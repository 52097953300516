<template>
  <div class="login-container">
    <div class="login-content">
      <div class="title">短信管理平台</div>
      <div class="login">
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
        >
          <a-form-item>
            <a-input
              size="large"
              v-decorator="[
          'userName',
          { rules: [{ required: true, message: '请输入账户名' }] }
        ]"
              placeholder="请输入账户名"
            >
              <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              size="large"
              v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入密码' }] }
        ]"
              type="password"
              placeholder="请输入密码"
            >
              <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              :loading="logging"
              type="primary"
              style="width: 100%;margin-top: 24px"
              html-type="submit"
              size="large"
              class="login-form-button"
            >登 录</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="record-number">
      <a href="http://www.beian.miit.gov.cn">湘ICP备17021768号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      logging: false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {},
  methods: {
    // 登录
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.logging = true;
          let data = {
            username: values.userName,
            password: values.password
          };
          this.$http.json_post("/manager/login",data).then(res => {
            this.logging = false;
            if (res.result == 200) {
              this.$router.push("/dataClick");
            }
          }).catch(err => {
            this.logging = false;
          });
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-title {
  position: absolute;
  margin-left: 20px;
  font-size: 33px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
}
.record-number {
  width: 380px;
  margin: 0 auto 40px;
  text-align: center;
}
.record-number a {
  color: #000;
  font-size: 16px;
}
.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5 url("../assets/TVYTbAXWheQpRcWDaDMu.svg") no-repeat center
    110px;
  background-size: 100%;
}
.login-content {
  padding: 200px 0 24px;
  flex: 1;
}
.login {
  width: 368px;
  margin: 0 auto;
}
.login-content .title {
  width: 368px;
  text-align: center;
  margin: 0 auto;
  font-size: 33px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}
</style>
