import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from './lib/antd'
import http from "./lib/axios"
import common from "./lib/common"

import VueClipboard from 'vue-clipboard2'
import syncLoading from './lib/syncLoading.js' // 引入loading

Vue.use(syncLoading) // 全局使用loading
Vue.use(VueClipboard)
Vue.use(Antd)

Vue.prototype.$http = http
Vue.prototype.$common = common

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
