<template>
  <div class="urlUvMonitor">
    <div class="searchCondition">
      <div class="searchConditionItem">
        链接：
        <a-input
          placeholder="请输入链接"
          v-model="url"
          @keyup.enter="query(1)"
          allow-clear
        />
      </div>
      <div class="searchCondition">
        <a-button type="primary" @click="query(1)">查询</a-button>
        <a-button type="primary" @click="add">新增</a-button>
      </div>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
    >
      <span slot="isShortUrl" slot-scope="text, record">
        <a-tag v-if="text" color="blue">短链</a-tag>
        <a-tag v-else color="green">长链</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="editClick(record)"> 编辑 </a-button>
        <a-button type="link" @click="deleteClick(record)" style="color: red">
          删除
        </a-button>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <div class="switch">
        <a-switch
          checked-children="短链"
          un-checked-children="长链"
          v-model="isShortUrlAdd"
        />
      </div>
      <a-form>
        <a-form-item
          label="链接:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="urlAdd" placeholder="请输入链接" />
        </a-form-item>
        <a-form-item
          label="uv数:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="uvAdd" placeholder="请输入uv数" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/urlUvMonitor.js";

export default {
  name: "urlUvMonitor",
  data() {
    return {
      isShortUrlAdd: true,
      url: "",
      urlAdd: "",
      uvAdd: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "链接",
          dataIndex: "url",
          align: "center",
        },
        {
          title: "uv数",
          dataIndex: "uv",
          align: "center",
        },
        {
          title: "短链/长链",
          dataIndex: "isShortUrl",
          align: "center",
          scopedSlots: { customRender: "isShortUrl" },
        },
        {
          title: "操作人",
          dataIndex: "userName",
          align: "center",
        },

        {
          title: "创建时间",
          align: "center",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      title: "新增",
      id: "",
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        url: this.url,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 新增
    add() {
      this.isShortUrlAdd = true;
      this.urlAdd = "";
      this.title = "新增";
      this.uvAdd = undefined;
      this.addKeyVisible = true;
    },
    editClick(record) {
      this.isShortUrlAdd = record.isShortUrl;
      this.urlAdd = record.url;
      this.title = "编辑";
      this.uvAdd = record.uv;
      this.id = record.id;
      this.addKeyVisible = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        isShortUrl: this.isShortUrlAdd,
        url: this.urlAdd,
        uv: this.uvAdd,
      };
      if (this.title == "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.id;
        api.edit(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.isShortUrlAdd = true;
      this.urlAdd = "";
      this.uvAdd = "";
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除",
        content: (h) => <div style="color:red;">确定要删除?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          api.deleteData(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.action-class {
  .switch {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    margin-bottom: 24px;
    padding-left: 94.16px;
    vertical-align: top;
    .ant-switch {
      background-color: #52c41a;
    }
    .ant-switch-checked {
      background-color: #1890ff;
    }
  }
}
</style>
