import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import login from "./views/login.vue";
import dataClick from "./components/dataClick.vue";
import clickDataSource from "./components/clickDataSource.vue";
import shortUrlManage from "./components/shortUrlManage.vue";
import shortUrlList from "./components/shortUrlList.vue";
import shortUrlCode from "./components/shortUrlCode.vue";
import shortUrlPhone from "./components/shortUrlPhone.vue";
import shortUrlReplace from "./components/shortUrlReplace.vue";
import domainInfo from "./components/domainInfo.vue";
import parameter from "./components/parameter.vue";
import urlUvMonitor from "./components/urlUvMonitor.vue";
import longUrl from "./components/longUrl.vue";
import longUrlGroup from "./components/longUrlGroup.vue";
import longUrlCheckTemplate from "./components/longUrlCheckTemplate.vue";
import longUrlList from "./components/longUrlList.vue";
import pvClick from "./components/pvClick.vue";
import pvClickLong from "./components/pvClickLong.vue";
import pvClickShort from "./components/pvClickShort.vue";
import pvClickSource from "./components/pvClickSource.vue";
import shortList from "./components/shortList.vue";
import shortUrlConfig from "./components/shortUrlConfig.vue";
import clickCallback from "./components/clickCallback.vue";
import clickCallbackUrl from "./components/clickCallbackUrl.vue";
import clickCallbackUrlUserRelation from "./components/clickCallbackUrlUserRelation.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      name: "home",
      redirect: "/dataClick",
      components: {
        first: Home,
      },
      children: [
        {
          path: "/dataClick",
          name: "dataClick",
          redirect: "/dataClick/clickDataSource",
          components: {
            second: dataClick,
          },
          children: [
            {
              path: "/dataClick/clickDataSource",
              name: "clickDataSource",
              components: {
                three: clickDataSource,
              },
            },
            {
              path: "/dataClick/urlUvMonitor",
              name: "urlUvMonitor",
              components: {
                three: urlUvMonitor,
              },
            },
          ],
        },
        {
          path: "/shortUrlManage",
          name: "shortUrlManage",
          redirect: "/shortUrlManage/shortUrlList",
          components: {
            second: shortUrlManage,
          },
          children: [
            {
              path: "/shortUrlManage/shortUrlList",
              name: "shortUrlList",
              components: {
                three: shortUrlList,
              },
            },
            {
              path: "/shortUrlManage/shortUrlCode",
              name: "shortUrlCode",
              components: {
                three: shortUrlCode,
              },
            },
            {
              path: "/shortUrlManage/shortUrlPhone",
              name: "shortUrlPhone",
              components: {
                three: shortUrlPhone,
              },
            },
            {
              path: "/shortUrlManage/shortUrlReplace",
              name: "shortUrlReplace",
              components: {
                three: shortUrlReplace,
              },
            },
          ],
        },
        {
          path: "/domainInfo",
          name: "domainInfo",
          components: {
            second: domainInfo,
          },
        },
        {
          path: "/parameter",
          name: "parameter",
          components: {
            second: parameter,
          },
        },
        {
          path: "/longUrl",
          name: "longUrl",
          components: {
            second: longUrl,
          },
          redirect: "/longUrl/longUrlList",
          children: [
            {
              path: "/longUrl/longUrlList",
              name: "longUrlList",
              components: {
                three: longUrlList,
              },
            },
            {
              path: "/longUrl/longUrlGroup",
              name: "longUrlGroup",
              components: {
                three: longUrlGroup,
              },
            },
            {
              path: "/longUrl/longUrlCheckTemplate",
              name: "longUrlCheckTemplate",
              components: {
                three: longUrlCheckTemplate,
              },
            },
          ],
        },
        {
          path: "/clickCallback",
          name: "clickCallback",
          components: {
            second: clickCallback,
          },
          redirect: "/clickCallback/clickCallbackUrl",
          children: [
            {
              path: "/clickCallback/clickCallbackUrl",
              name: "clickCallbackUrl",
              components: {
                three: clickCallbackUrl,
              },
            },
            {
              path: "/clickCallback/clickCallbackUrlUserRelation",
              name: "clickCallbackUrlUserRelation",
              components: {
                three: clickCallbackUrlUserRelation,
              },
            },
          ],
        },
        {
          path: "/shortUrlConfig",
          name: "shortUrlConfig",
          components: {
            second: shortUrlConfig,
          },
        },
        {
          path: "/pvClick",
          name: "pvClick",
          redirect: "/pvClick/pvClickLong",
          components: {
            second: pvClick,
          },
          children: [
            {
              path: "/pvClick/pvClickLong",
              name: "pvClickLong",
              components: {
                three: pvClickLong,
              },
            },
            {
              path: "/pvClick/pvClickShort",
              name: "pvClickShort",
              components: {
                three: pvClickShort,
              },
            },
            {
              path: "/pvClick/pvClickSource",
              name: "pvClickSource",
              components: {
                three: pvClickSource,
              },
            },
          ],
        },
        {
          path: "/shortList",
          name: "shortList",
          components: {
            second: shortList,
          },
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      components: {
        first: login,
      },
    },
    {
      path: "/",
      redirect: "/home",
    },
  ],
});
