<template>
  <div class="smsStatusInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        长链：
        <a-input v-model="longUrl" allowClear placeholder="请输入长链" />
      </div>
      <div class="searchConditionItem">
        短链：
        <a-input v-model="shortUrl" allowClear placeholder="请输入短链" />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
      </div>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="shortPagination"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "pvClickSource",
  data() {
    return {
      longUrl: "",
      shortUrl: "",
      loadingTable: false,
      tableDataSource: [],
      shortPagination: {},
      currentPage: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "来源",
          dataIndex: "source",
        },
        {
          title: "短链",
          dataIndex: "shortUrl",
        },
        {
          title: "长链",
          dataIndex: "longUrl",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      let data = {
        pageNo: index || this.currentPage,
        pageSize: 10,
        longUrl: this.longUrl,
        shortUrl: this.shortUrl,
      };
      this.getSmsStatusInfo(data);
    },
    // 查询点击数据列表
    getSmsStatusInfo(data) {
      this.loadingTable = true;
      this.$http
        .get("/upv/sourcePage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.shortPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.shortChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 管理分页
    shortChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.source + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/upv/deleteSource", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.shortUrl = "";
              this.longUrl = "";
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
