<template>
  <div class="shortUrlManage">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="分页查询">
        <router-view name="three" v-if="active == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane key="2" tab="code查询">
        <router-view name="three" v-if="active == '2'"></router-view>
      </a-tab-pane>
      <a-tab-pane key="3" tab="手机号查询">
        <router-view name="three" v-if="active == '3'"></router-view>
      </a-tab-pane>
      <a-tab-pane key="4" tab="自动替换">
        <router-view name="three" v-if="active == '4'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "shortUrlManage",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/shortUrlManage/shortUrlList") {
          this.active = "1";
        } else if (newVal == "/shortUrlManage/shortUrlCode") {
          this.active = "2";
        } else if (newVal == "/shortUrlManage/shortUrlPhone") {
          this.active = "3";
        } else if (newVal == "/shortUrlManage/shortUrlReplace") {
          this.active = "4";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/shortUrlManage/shortUrlList") return;
          this.$router.replace("/shortUrlManage/shortUrlList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/shortUrlManage/shortUrlCode") return;
          this.$router.replace("/shortUrlManage/shortUrlCode");
        }
        if (newVal === "3") {
          if (this.$route.path == "/shortUrlManage/shortUrlPhone") return;
          this.$router.replace("/shortUrlManage/shortUrlPhone");
        }
        if (newVal === "4") {
          if (this.$route.path == "/shortUrlManage/shortUrlReplace") return;
          this.$router.replace("/shortUrlManage/shortUrlReplace");
        }
      },
    },
  },
};
</script>
