<template>
  <div class="smsStatusInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        主链：
        <a-input v-model="shortCode" placeholder="请输入主链" />
      </div>
      <div class="searchConditionItem">
        辅链：
        <a-input v-model="replaceShortCode" placeholder="请输入辅链" />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button type="primary" @click="addClick">新 增</a-button>
      </div>
    </div>
    <!-- 短链替换配置列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="shortPagination"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="enabled" slot-scope="text">
        <a-tag v-if="text" color="green">启用</a-tag>
        <a-tag v-else color="red">禁用</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a
        ><a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 修改短链替换配置 -->
    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      :afterClose="updateClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="主链:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="shortCodeAdd" placeholder="请输入主链" />
        </a-form-item>
        <a-form-item
          label="辅链:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="replaceShortCodeAdd" placeholder="请输入辅链" />
        </a-form-item>
        <a-form-item
          label="启用:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
        <a-form-item
          label="随即范围起:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="rateStart" placeholder="请输入随即范围起" />
        </a-form-item>
        <a-form-item
          label="随即范围止:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="rateEnd" placeholder="请输入随即范围止" />
        </a-form-item>
        <!-- <a-form-item
          label="优先级:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="order" placeholder="请输入优先级"/>
        </a-form-item> -->
        <a-form-item
          label="禁用城市:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="forbiddenProvince" placeholder="请输入禁用城市" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "shortUrlConfig",
  data() {
    return {
      shortCode: "",
      updateShow: false,
      updateId: "",
      shortCodeAdd: "",
      replaceShortCodeAdd: "",
      enabled: true,
      rateStart: "1",
      rateEnd: "300",
      forbiddenProvince: "北京,上海",
      order: "1",
      remark: "",
      title: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "主链",
          dataIndex: "shortCode",
        },
        {
          title: "辅链",
          dataIndex: "replaceShortCode",
        },
        {
          title: "启用",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "随机范围起",
          dataIndex: "rateStart",
        },
        {
          title: "随机范围止",
          dataIndex: "rateEnd",
        },
        {
          title: "禁用城市",
          dataIndex: "forbiddenProvince",
        },
        {
          title: "优先级",
          dataIndex: "order",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      shortPagination: {},
      currentPage: 1,
      syncLoading: null,
      replaceShortCode: "",
      loading: false,
      loadingTable: false,
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击修改
    modifyClick(val) {
      this.updateId = val.id;
      this.shortCodeAdd = val.shortCode;
      this.replaceShortCodeAdd = val.replaceShortCode;
      this.enabled = val.enabled;
      this.rateStart = val.rateStart;
      this.rateEnd = val.rateEnd;
      this.forbiddenProvince = val.forbiddenProvince;
      this.order = val.order;
      this.remark = val.remark;
      this.updateShow = true;
      this.title = "编辑";
    },
    //  确定修改
    update_submit() {
      let data = {
        shortCode: this.shortCodeAdd,
        replaceShortCode: this.replaceShortCodeAdd,
        enabled: this.enabled,
        rateStart: this.rateStart,
        rateEnd: this.rateEnd,
        forbiddenProvince: this.forbiddenProvince,
        order: this.order,
        remark: this.remark,
      };
      this.loading = true;
      if (this.title === "新增") {
        this.$http
          .json_post("/dynamicReplaceConfig/add", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.updateShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else if (this.title === "编辑") {
        data.id = this.updateId;
        this.$http
          .json_post("/dynamicReplaceConfig/edit", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.updateShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    //  关闭修改
    updateClose() {
      this.shortCodeAdd = "";
      this.replaceShortCodeAdd = "";
      this.enabled = true;
      this.rateStart = "1";
      this.rateEnd = "300";
      this.forbiddenProvince = "北京,上海";
      this.order = "1";
      this.remark = "";
    },
    // 点击查询
    inquire(index) {
      let data = {
        pageNo: index || this.currentPage,
        pageSize: 10,
        shortCode: this.shortCode,
        replaceShortCode: this.replaceShortCode,
      };
      this.getSmsStatusInfo(data);
    },
    // 查询点击数据列表
    getSmsStatusInfo(data) {
      this.loadingTable = true;
      this.$http
        .get("/dynamicReplaceConfig/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.shortPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.shortChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 管理分页
    shortChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 点击新增
    addClick() {
      this.title = "新增";
      this.updateShow = true;
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.shortCode + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/dynamicReplaceConfig/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
