<template>
  <div class="clickCallbackUrlUserRelation">
    <div class="searchCondition">
      <div class="searchConditionItem">
        用户：
        <a-input
          placeholder="请输入用户"
          v-model="userName"
          @keyup.enter="query(1)"
          allow-clear
        ></a-input>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)">查询</a-button>
        <a-button type="primary" @click="add">新增</a-button>
      </div>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="用户:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="userNameAdd" placeholder="请输入用户" />
        </a-form-item>
        <a-form-item
          label="链接:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="urlIdAdd"
            placeholder="请选择链接"
            showSearch
            :filter-option="false"
            @search="getClickCallbackUrlList"
          >
            <a-select-option
              v-for="i in clickCallbackUrlList"
              :key="i.id"
              :value="i.id"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/clickCallbackUrlUserRelation.js";

export default {
  name: "clickCallbackUrlUserRelation",
  data() {
    return {
      userName: "",
      userNameAdd: "",
      urlIdAdd: undefined,
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "用户",
          dataIndex: "userName",
        },
        {
          title: "链接",
          dataIndex: "url",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      clickCallbackUrlList: [],
    };
  },
  created() {
    this.getClickCallbackUrlList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        userName: this.userName,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.clickCallbackUrlList.forEach((urlItem) => {
              this.tableDataSource.forEach((item) => {
                if (item.urlId == urlItem.id) {
                  this.$set(item, "url", urlItem.url);
                }
              });
            });
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    getClickCallbackUrlList(name) {
      api.getClickCallbackUrlList({ name }).then((res) => {
        if (res.result == 200) {
          this.clickCallbackUrlList = res.data;
          if (this.tableDataSource.length == 0) {
            this.query(1);
          }
        }
      });
    },
    // 新增
    add() {
      this.addKeyVisible = true;
      this.userNameAdd = "";
      this.urlIdAdd = undefined;
    },
    // 确定新增
    add_submit() {
      let data = {
        userName: this.userNameAdd,
        urlId: this.urlIdAdd,
      };
      api.addData(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("添加成功");
          this.query();
        }
      });
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.userNameAdd = "";
      this.urlIdAdd = undefined;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
