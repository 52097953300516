<template>
  <div class="shortLinks content_view">
    <a-tabs @change="callback">
      <!-- 智能短链 -->
      <a-tab-pane tab="智能短链" key="Intelligence" class="a-tabs">
        <!-- 操作 -->
        <div class="searchCondition">
          <div class="searchConditionItem">
            短链名称：
            <a-input
              placeholder="请输入短链名称"
              v-model="activityCodeTitle"
              @keyup.enter="inquire(1, 'Intelligence')"
            />
          </div>
          <div class="searchConditionItem">
            添加时间：
            <a-range-picker
              :disabledDate="disabledDate"
              :defaultValue="[moment().startOf('month'), moment().endOf('day')]"
              :ranges="{
                今天: [moment(), moment()],
                本月: [moment().startOf('month'), moment().endOf('day')],
              }"
              format="YYYY-MM-DD"
              @change="onChangeIntelligenceTime"
            />
          </div>
          <div class="searchConditionItem">
            长链网址：
            <a-input
              placeholder="请输入长链"
              v-model="activitySubCodeTitle"
              @keyup.enter="inquire(1, 'Intelligence')"
            />
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="inquire(1, 'Intelligence')"
              >查询</a-button
            >
          </div>
        </div>
        <!-- 表格 -->
        <a-table
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="columnsIntelligence"
          :dataSource="dataIntelligence"
          :pagination="paginationIntelligence"
          :loading="loadingTable"
          size="middle"
        >
          <span slot="longUrl" slot-scope="text, record" class="logUrl-class">
            <a-tooltip placement="topLeft">
              <template slot="title">{{ record.longUrl }}</template>
              <a :href="record.longUrl" target="_blank">{{ text }}</a>
            </a-tooltip>
            <a
              href="javascript:;"
              class="copy-class"
              v-clipboard:copy="record.longUrl"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-if="record.isCopyShow"
              >复制</a
            >
          </span>
          <span slot="shortUrlCount" slot-scope="text, record">
            <a href="javascript:;" @click="analysisClick(record)">{{ text }}</a>
          </span>
          <span slot="status" slot-scope="text">
            <a-tag v-if="text === 'CREATING'" color="orange"
              >创建中
              <a-icon type="loading" class="icon-loading" />
            </a-tag>
            <a-tag v-if="text === 'NORMAL'" color="green">正常</a-tag>
            <a-tag v-if="text === 'CLOSED'" color="orange">已关闭</a-tag>
            <a-tag v-if="text === 'TERMINATED'" color="orange">已终止</a-tag>
          </span>
          <span slot="action" slot-scope="text, record">
            <a-popconfirm
              placement="right"
              okText="确认"
              cancelText="取消"
              @confirm="shortUrlDetection(record)"
            >
              <template slot="title"
                >是否确认检测{{ record.activityCodeLabel }}</template
              >
              <a href="javascript:;">短链检测</a>
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
      <!-- 普通短链 -->
      <a-tab-pane tab="普通短链" key="free" class="a-tabs">
        <!-- 操作 -->
        <div class="searchCondition">
          <div class="searchConditionItem">
            短链名称：
            <a-input
              placeholder="请输入短链名称"
              v-model="urlNameTitle"
              @keyup.enter="inquire(1, 'free')"
            />
          </div>
          <div class="searchConditionItem">
            添加时间：
            <a-range-picker
              :disabledDate="disabledDate"
              :defaultValue="[moment().startOf('month'), moment().endOf('day')]"
              :ranges="{
                今天: [moment(), moment()],
                本月: [moment().startOf('month'), moment().endOf('day')],
              }"
              format="YYYY-MM-DD"
              @change="onChangeFreeTime"
            />
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="inquire(1, 'free')">查询</a-button>
          </div>
        </div>
        <!-- 表格 -->
        <a-table
          :columns="columnsFree"
          :dataSource="dataFree"
          :pagination="paginationFree"
          :loading="loadingTable"
          size="middle"
        >
          <span slot="lurl" slot-scope="text, record" class="logUrl-class">
            <a-tooltip placement="topLeft">
              <template slot="title">{{ record.longUrl }}</template>
              <a :href="record.longUrl" target="_blank">{{ text }}</a>
            </a-tooltip>
            <a
              href="javascript:;"
              class="copy-class"
              v-clipboard:copy="record.longUrl"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-if="record.isCopyShow"
              >复制</a
            >
          </span>
          <span slot="surl" slot-scope="text, record">
            <a-tooltip placement="topLeft">
              <template slot="title">{{ record.shortUrl }}</template>
              <a :href="record.shortUrl" target="_blank">{{ text }}</a>
            </a-tooltip>
          </span>
          <span slot="action" slot-scope="text, record">
            <a
              href="javascript:;"
              @click="clickProhibit(record)"
              style="color: #f56c6c"
              >禁用</a
            >
            <a-divider type="vertical" />
            <a
              href="javascript:;"
              @click="clickDelete(record)"
              style="color: #f56c6c"
              >删除</a
            >
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <!-- 批次详情 -->
    <a-modal
      title="批次详情"
      v-model="batchDetailListShow"
      :maskClosable="false"
      width="900px"
      :afterClose="batchDetailListClose"
      class="actionSub-class"
      :footer="null"
    >
      <a-table
        :columns="batchDetailListColumns"
        :dataSource="dataAnalysis"
        :pagination="paginationAnalysis"
        size="small"
      ></a-table>
    </a-modal>
    <!-- 发送短信 -->
    <a-modal
      :title="'【' + sendShortName + '】发送短信'"
      v-model="sendShortShow"
      :maskClosable="false"
      :afterClose="sendShortClose"
      width="1000px"
      class="actionSub-class"
    >
      <div class="number-pool">
        <div class="number-pool-item">
          <div>有效号码</div>
          <a href="javascript:;" class="number" @click="detailsClick()">{{
            pooiForm.validNumber
          }}</a>
        </div>
        <div class="number-pool-item">
          <div>移动</div>
          <a
            href="javascript:;"
            class="number"
            @click="detailsClick('中国移动')"
            >{{ pooiForm.moveNumber }}</a
          >
        </div>
        <div class="number-pool-item">
          <div>联通</div>
          <a
            href="javascript:;"
            class="number"
            @click="detailsClick('中国联通')"
            >{{ pooiForm.unicomNumber }}</a
          >
        </div>
        <div class="number-pool-item">
          <div>电信</div>
          <a
            href="javascript:;"
            class="number"
            @click="detailsClick('中国电信')"
            >{{ pooiForm.telecomNumber }}</a
          >
        </div>
      </div>
      <div class="contentLR">
        <div class="left">
          <a-form>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              label="精准发送:"
            >
              <a-switch
                v-model="isPrecise"
                @change="preciseChange"
                size="small"
              />
              <a-checkbox-group
                v-model="operatorList"
                class="operatorList-checkbox"
                v-if="isPreciseCheck"
              >
                <a-checkbox value="中国移动">移动</a-checkbox>
                <a-checkbox value="中国联通">联通</a-checkbox>
                <a-checkbox value="中国电信">电信</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              label="账号"
            >
              <a-select
                v-model="sendForm.userSmsAccountId"
                placeholder="请选择账号"
                class="select-tag"
                @change="userSmsChange"
                show-search
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="i in userLoginNameList"
                  :key="i.id"
                  :value="i.id"
                  >{{ i.alias }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              label="签名:"
            >
              <a-select
                :value="sendForm.signId"
                @change="sighChange"
                placeholder="请选择签名"
                class="select-tag"
                show-search
                :filter-option="filterOption1"
              >
                <a-select-option
                  v-for="i in signList"
                  :key="i.signId"
                  :value="i.signId"
                  >{{ i.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 20 }"
              label="短信内容:"
            >
              <div class="smsContentTemplate-c">
                <!-- <a href="javascript:void(0)" @click="smsContentTemplateClick"
                  >使用模版</a
                > -->
              </div>
              <sms-input
                :maxUrlLength="maxUrlLength"
                :textareaSMS="textarea"
                :prefixValue="prefix"
                :sign="sendForm.sign"
                @change="textarea_sms"
                @prefixChange="prefixChange"
              ></sms-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <i style="display: inline-block; margin-left: 116px"></i>
              <a-checkbox :checked="checkedTiming" @change="onChangeTiming"
                >定时发送</a-checkbox
              >
              <a-date-picker
                v-if="isTime"
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                v-model="sendForm.time"
                placeholder="选择日期时间"
              />
              <div v-if="isRetransmission">
                <i style="display: inline-block; margin-left: 116px"></i>
                <a-checkbox v-model="retransmission">
                  <span style="color: #f56c6c"
                    >该分组已发送过短信, 确认还需要再发送一次</span
                  >
                </a-checkbox>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="right">
          <div
            class="bubble"
            v-if="this.sendForm.sign !== undefined || this.textarea !== ''"
          >
            {{ sendForm.sign }}{{ textarea }}
          </div>
        </div>
      </div>

      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="sendShortShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="sendShort_submit"
            :loading="sendLoading"
            >确定发送</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 添加短链接 -->
    <a-modal
      :title="shortModalTitle"
      v-model="shortLinkShow"
      :maskClosable="false"
      :afterClose="shortLinkClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          v-if="isZhiNengShow && isAddShortModal"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="号码文件:"
          required
        >
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemoveMale"
            :beforeUpload="beforeUploadMale"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域以上传</p>
          </a-upload-dragger>
          <span class="dropbox-ts"
            >支持文件格式：.txt。
            <!-- <a href="javascript:;" >下载模板</a> -->
          </span>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="短链名称:"
          required
        >
          <div class="s-url-input">
            <a-input v-model="name" placeholder="请输入短链名称" />
            <span class="dropbox-ts">示例：官网宣传首页</span>
            <a
              href="javascript:;"
              @click="setBatch(name)"
              v-if="isZhiNengShow"
              class="s-url-a"
              >设置批次</a
            >
          </div>
        </a-form-item>
        <a-form-item
          v-if="batchShow"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="生成批次:"
        >
          <a-input v-model="activitySubCode" placeholder="请输入批次" />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="长网址链接:"
          required
        >
          <a-input
            v-model="longUrl"
            placeholder="请输入长网址链接"
            @blur="compareToLongUrl(longUrl)"
          />
          <span v-if="urlFormat" class="dropbox-ts" style="color: red"
            >长网址链接格式不正确</span
          >
          <span class="dropbox-ts">示例：http://www.xxxx.com</span>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="选择域名:"
          required
        >
          <a-select v-model="selectDomain" placeholder="请选择域名">
            <a-select-option
              v-for="i in selectDomainList"
              :key="i.value"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="业务类型:"
          required
          v-if="isZhiNengShow && isAddShortModal"
        >
          <a-select v-model="selectGory" placeholder="请选择类型">
            <a-select-option
              v-for="i in categoryList"
              :key="i.value"
              :value="i.value"
            >
              {{ i.key }}
            </a-select-option>
            <!-- <a-select-option value="教育"> 教育 </a-select-option>
            <a-select-option value="贷款"> 贷款 </a-select-option>
            <a-select-option value="保险"> 保险 </a-select-option> -->
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="数据来源:"
          v-if="isZhiNengShow && isAddShortModal"
        >
          <a-select v-model="source" placeholder="请选择类型">
            <a-select-option
              v-for="i in sourceList"
              :key="i.value"
              :value="i.value"
            >
              {{ i.key }}
            </a-select-option>
            <!-- <a-select-option value="广点通"> 广点通 </a-select-option> -->
            <!-- <a-select-option value="头条"> 头条 </a-select-option>
            <a-select-option value="模型"> 模型 </a-select-option>
            <a-select-option value=""> 未知 </a-select-option> -->
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="点击回调:"
          v-if="isZhiNengShow"
        >
          <a-input v-model="clickCallbackUrl" placeholder="请输入点击回调" />
        </a-form-item>
        <!-- <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="数据标记:"
          v-if="isZhiNengShow && isAddShortModal"
        >
          <a-input
            v-model="dataSign"
            placeholder="请输入数据标记"
          />
        </a-form-item> -->
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shortLinkShow = false">取消</a-button>
          <a-button
            v-if="isAddShortModal"
            key="submit"
            type="primary"
            @click="shortLink_submit"
            :disabled="shortLinkDisabled"
            >确定</a-button
          >
          <a-button
            v-if="!isAddShortModal"
            key="submit"
            type="primary"
            @click="longUrl_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 分析 -->
    <a-modal
      :title="'【' + sendShortName + '】分析'"
      v-model="analysisShow"
      :maskClosable="false"
      :footer="null"
      width="800px"
      class="actionSub-class"
    >
      <div class="shortLinks-button">
        <a-button type="primary" @click="exportClick">导出</a-button>
        <a-button @click="inquireAnalysis">刷新</a-button>
      </div>
      <a-table
        :columns="columnsAnalysis"
        :dataSource="dataAnalysis"
        :pagination="paginationAnalysis"
        size="small"
      >
        <span slot="shortUrl" slot-scope="text, record">
          <a :href="record.shortUrl" target="_blank">{{ text }}</a>
        </span>
        <span slot="longUrl" slot-scope="text, record">
          <a :href="record.longUrl" target="_blank">{{ text }}</a>
        </span>
        <span slot="pv" slot-scope="text, record">
          <a href="javascript:;" @click="clickPv(record)">{{ text }}</a>
        </span>
      </a-table>
    </a-modal>
    <!-- 点击显示 -->
    <a-drawer
      title="PV详情"
      placement="right"
      :width="550"
      :closable="false"
      @close="onClosePv"
      :visible="visiblePv"
    >
      <a-table
        :columns="columnsPv"
        :dataSource="dataPv"
        :pagination="paginationPv"
        size="middle"
      ></a-table>
    </a-drawer>
  </div>
</template>
<script>
import moment from "moment";
import reqwest from "reqwest";
export default {
  data() {
    return {
      sendLoading: false,
      randomNum: "",
      //   smsContentTemplateShow: false,
      intelligenceTime: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD"),
      ],
      freeTime: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD"),
      ],
      shortLinkDisabled: false,
      selectDomain: undefined,
      selectDomainList: [],
      selectGory: "游戏",
      categoryList: [],
      source: "未知",
      sourceList: [],
      clickCallbackUrl: "",
      shortModalTitle: "添加短链接",
      isAddShortModal: false,
      syncLoading: null,
      analysisPageNo: 1,
      urlFormat: false,
      prefix: undefined,
      pooiForm: {
        validNumber: 0,
        moveNumber: 0,
        unicomNumber: 0,
        telecomNumber: 0,
      },
      isPrecise: false,
      operatorList: [],
      isPreciseCheck: false,
      isCityDis: false,
      isShortDis: false,
      isEndNumDis: false,
      batchShow: false,
      shortCode: "",
      columnsPv: [
        {
          title: "点击时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "号码",
          dataIndex: "userCode",
        },
        {
          title: "ip",
          dataIndex: "ip",
        },
        {
          title: "设备",
          dataIndex: "deviceInfo",
        },
      ],
      dataPv: [],
      paginationPv: {},
      visiblePv: false,
      groupId: "",
      analysisShow: false,
      columnsAnalysis: [
        {
          title: "号码",
          dataIndex: "userCode",
        },
        {
          title: "短链",
          dataIndex: "shortUrl",
          scopedSlots: { customRender: "shortUrl" },
        },
        {
          title: "长链",
          dataIndex: "longUrl",
          scopedSlots: { customRender: "longUrl" },
        },
        {
          title: "省份",
          dataIndex: "province",
        },
        {
          title: "城市",
          dataIndex: "city",
        },
        {
          title: "运营商",
          dataIndex: "operator",
        },
        {
          title: "PV",
          dataIndex: "clickCount",
          // scopedSlots: { customRender: "pv" },
        },
        // {
        //   title: "UV",
        //   dataIndex: "uniqueClickCount"
        // }
      ],
      dataAnalysis: [],
      paginationAnalysis: {},
      isTime: false,
      sendShortShow: false,
      sendShortName: "",
      checkedTiming: false,
      sendForm: {
        // accessKey: undefined,
        userSmsAccountId: undefined,
        sendCount: 0,
        activityCode: "",
        activitySubCode: "",
        sign: "",
        // platform: "",
        signId: undefined,
        time: null,
      },
      userLoginNameList: [],
      textarea: "",
      signList: [],
      isZhiNengShow: true,
      shortLinkShow: false,
      isBatchDis: true,
      paginationIntelligence: {},
      columnsIntelligence: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "短链名称",
          dataIndex: "activityCodeLabel",
        },
        {
          title: "长链",
          dataIndex: "longUrlLabel",
          scopedSlots: { customRender: "longUrl" },
          customCell: this.mouseoverLongUrl,
          width: 450,
        },
        {
          title: "短链域名",
          dataIndex: "domain",
        },
        // yc
        {
          title: "业务类型",
          dataIndex: "category",
        },
        {
          title: "短链数量",
          dataIndex: "shortUrlCount",
          scopedSlots: { customRender: "shortUrlCount" },
        },
        {
          title: "总访问PV",
          dataIndex: "clickCount",
        },
        {
          title: "独立UV",
          dataIndex: "uniqueClickCount",
        },
        {
          title: "IP",
          dataIndex: "ipCount",
        },
        {
          title: "添加时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 145,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataIntelligence: [],
      paginationFree: {},
      loadingTable: false,
      columnsFree: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "短链名称",
          dataIndex: "urlName",
        },
        {
          title: "长链",
          dataIndex: "longUrlLabel",
          scopedSlots: { customRender: "lurl" },
          customCell: this.mouseoverLongUrlFree,
          width: 450,
        },
        {
          title: "短链",
          dataIndex: "shortUrl",
          scopedSlots: { customRender: "surl" },
        },
        {
          title: "UV",
          dataIndex: "uniqueClickCount",
        },
        {
          title: "PV",
          dataIndex: "clickCount",
        },
        {
          title: "添加时间",
          dataIndex: "gmtCreated",
        },
      ],
      dataFree: [],
      startTime: null,
      endTime: null,
      activityCodeTitle: "",
      activitySubCodeTitle: "",
      urlNameTitle: "",
      selectedRowKeys: [],
      fileList: [],
      name: "",
      // dataSign: "",
      activitySubCode: "",
      longUrl: "",
      currentPage: 1,
      shortUrlGroupId: "",
      platform: "",
      platformList: [],
      maxUrlLength: 0,
      operator: "",
      asynTime: null,
      batchDetailListShow: false,
      batchDetailListColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "key",
          width: 60,
        },
        {
          title: "电话",
          dataIndex: "userCode",
        },
        {
          title: "省份",
          dataIndex: "province",
        },
        {
          title: "城市",
          dataIndex: "city",
        },
        {
          title: "运营商",
          dataIndex: "operator",
        },
      ],
      batchDetailListTable: [],
      batchDetailListPagination: {},
      shortUrlName: "",
      isRetransmission: false,
      retransmission: false,
      checkLoading: null,
    };
  },
  mounted() {
    this.inquire(1, "Intelligence");
  },
  methods: {
    shortUrlDetection(val) {
      this.checkLoading = this.$syncLoading({
        message: "检测中...",
      });
      this.$http
        .get("/shortUrl/checkShortUrlGroup", { groupId: val.id })
        .then((res) => {
          if (res.result === 200) {
            this.checkLoading.close();
            if (res.data) {
              this.$message.success("检测通过");
            } else {
              this.$message.error("检测不通过");
            }
          }
        })
        .catch(() => {
          this.checkLoading.close();
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOption1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    moment, // 引用moment方法
    // 获取短信内容
    textarea_sms(val) {
      this.textarea = val;
    },
    // 获取前缀
    prefixChange(val) {
      this.prefix = val;
    },
    openShort(val) {
      this.$confirm({
        title: "确定开启",
        content: (h) => (
          <div style="color:red;">
            确定开启【{val.activityCodeLabel}】短链？
          </div>
        ),
        onOk: () => {
          this.$http
            .get("/shortUrl/openShortUrlGroup", { groupId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("开启成功");
                this.inquire(null, "Intelligence");
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // 点击关闭
    closeShort(val) {
      this.$confirm({
        title: "确定关闭",
        content: (h) => (
          <div style="color:red;">
            确定关闭【{val.activityCodeLabel}】短链？
          </div>
        ),
        onOk: () => {
          this.$http
            .get("/shortUrl/closeShortUrlGroup", { groupId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("关闭成功");
                this.inquire(null, "Intelligence");
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // yc点击终止
    endShort(val) {
      this.$confirm({
        title: "确定终止",
        content: (h) => (
          <div style="color:red;">
            确定终止【{val.activityCodeLabel}】短链？
          </div>
        ),
        onOk: () => {
          this.$http
            .get("/shortUrl/terminatedShortUrlGroup", { groupId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("终止成功");
                this.inquire(null, "Intelligence");
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // 点击删除
    deleteShort(val) {
      this.$confirm({
        title: "确定删除",
        content: (h) => (
          <div style="color:red;">
            确定删除【{val.activityCodeLabel}】短链？
          </div>
        ),
        onOk: () => {
          this.$http
            .get("/shortUrl/deleteShortUrlGroup", { groupId: val.id })
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire(null, "Intelligence");
              }
            });
        },
        onCancel() {},
        class: "test",
      });
    },
    // 查询短链域名
    getDomainList() {
      this.selectDomainList = [];
      this.$http.get("/shortUrl/domainList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.selectDomainList.push({
              value: item,
              label: item,
            });
          });
        }
      });
    },
    // 点击修改长链
    modifyLongUrlClick(val) {
      this.getDomainList();
      this.isAddShortModal = false;
      this.groupId = val.id;
      this.shortModalTitle = "修改长链";
      this.name = val.activityCode;
      this.activitySubCode = val.activitySubCode;
      if (this.activitySubCode !== this.name) {
        this.batchShow = true;
      }
      this.longUrl = val.longUrl;
      // this.dataSign = val.dataSign;
      this.selectDomain = val.domain;
      this.clickCallbackUrl = val.clickCallbackUrl;
      this.shortLinkShow = true;
    },
    // 修改长链提交
    longUrl_submit() {
      if (!this.name) {
        this.$message.warning("请输入短链名称");
        return;
      }
      if (this.name.length > 32) {
        this.$message.warning("短链名称不能大于32个字符");
        return;
      }
      if (this.batchShow) {
        if (this.activitySubCode.length > 32) {
          this.$message.warning("批次名称不能大于32个字符");
          return;
        }
      } else {
        this.activitySubCode = this.name;
      }
      if (!this.longUrl) {
        this.$message.warning("请输入长网址链接");
        return;
      }
      if (!this.selectDomain) {
        this.$message.warning("请选择域名");
        return;
      }
      let data = {
        groupId: this.groupId,
        activityCode: this.name,
        activitySubCode: this.activitySubCode,
        newUrl: this.longUrl,
        domain: this.selectDomain,
        clickCallbackUrl: this.clickCallbackUrl,
      };
      this.shortLinkShow = false;
      this.syncLoading = this.$syncLoading({
        message: "长链修改中...",
      });
      this.$http
        .json_post("/shortUrl/modifyLongUrl", data)
        .then((res) => {
          this.syncLoading.close();
          if (res.result === 200) {
            this.$message.success("修改成功！");
            this.inquire(null, "Intelligence");
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    // 提示长链接格式是否正确
    compareToLongUrl(value) {
      if (value) {
        let httpIndex = value.indexOf("http://");
        let httpsIndex = value.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.urlFormat = false;
          return;
        }
        this.urlFormat = true;
      }
    },
    // 点击导出
    exportClick() {
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/shortUrl/exportGroupDetailList?groupId=" +
        this.groupId +
        "&shortUrlName=" +
        this.shortUrlName;
      window.location.href = href;
    },
    // 鼠标移入长链列--智能短链
    mouseoverLongUrl(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.dataIntelligence.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataIntelligence[rowIndex], "isCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.dataIntelligence.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataIntelligence[rowIndex], "isCopyShow", false);
              }
            });
          },
        },
      };
    },
    // 鼠标移入长链列--普通短链
    mouseoverLongUrlFree(record, rowIndex) {
      return {
        on: {
          // 事件
          click: (event) => {}, // 点击行
          dblclick: (event) => {},
          contextmenu: (event) => {},
          mouseenter: (event) => {
            // 鼠标移入行
            this.dataFree.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataFree[rowIndex], "isCopyShow", true);
              }
            });
          },
          mouseleave: (event) => {
            // 鼠标移出行
            this.dataFree.forEach((item) => {
              if (record.id === item.id) {
                this.$set(this.dataFree[rowIndex], "isCopyShow", false);
              }
            });
          },
        },
      };
    },
    // 点击批次详情
    detailsClick(operator) {
      this.batchDetailListShow = true;
      if (!operator) {
        this.operator = "";
      } else {
        this.operator = operator;
      }
      this.analysisPageNo = 1;
      let data = {
        groupId: this.shortUrlGroupId,
        operator: this.operator,
        pageNo: 1,
        pageSize: 10,
      };
      this.getGroupDetailList(data);
    },
    // 关闭批次详情
    batchDetailListClose() {
      this.dataAnalysis = [];
      this.operator = "";
    },
    // 刷新智能短链列表
    AsynIntelligence(ids) {
      if (this.asynTime) {
        clearInterval(this.asynTime);
        this.asynTime = null;
      }
      this.asynTime = setInterval(() => {
        let data = {
          ids: ids,
        };
        this.groupDetail(data);
      }, 1000);
    },
    // 请求刷新智能短链列表
    groupDetail(data) {
      this.$http.get("/shortUrl/groupDetail", data).then((res) => {
        if (res.result === 200) {
          let param = res.data;
          for (let i in param) {
            this.dataIntelligence.forEach((item) => {
              if (item.id == i) {
                if (param[i].status === "NORMAL") {
                  clearInterval(this.asynTime);
                  this.asynTime = null;
                  let datas = {
                    pageNo: this.currentPage,
                    pageSize: 10,
                  };
                  this.getGroupList(datas);
                }
              }
            });
          }
        }
      });
    },
    // 选择精准发送
    preciseChange(val) {
      this.isPreciseCheck = val;
      if (!val) {
        this.operatorList = [];
      }
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    // 点击设置批次
    setBatch(name) {
      this.batchShow = !this.batchShow;
      // if (name !== '') {
      //   this.activitySubCode = name;
      // }
    },
    // 点击pv
    clickPv(val) {
      this.visiblePv = true;
      this.shortCode = val.shortCode;
      let data = {
        shortCode: this.shortCode,
        pageNo: 1,
        pageSize: 10,
      };
      this.getShortUrlClickDetailList(data);
    },
    // 关闭pv
    onClosePv() {
      this.visiblePv = false;
      this.dataPv = [];
    },
    // 查询访问详情
    getShortUrlClickDetailList(data) {
      this.$http.get("/shortUrl/shortUrlClickDetailList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.data;
          list.forEach((item, index) => {
            item.key = index + 1;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          });
          this.paginationPv = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_Pv(current),
          };
          this.dataPv = list;
        }
      });
    },
    // pv分页
    currentPage_Pv(index) {
      let data = {
        shortCode: this.shortCode,
        pageNo: index,
        pageSize: 10,
      };
      this.getShortUrlClickDetailList(data);
    },
    // 获取平台
    getPlatformList() {
      this.platformList = [];
      this.$http.get("/sms/user/platformList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.platformList.push({
              label: item.name,
              value: item.accessKey,
              code: item.code,
              id: item.id,
            });
          });
          this.sendForm.platform = "";
          this.sendForm.accessKey = "";
          if (this.platformList.length > 0) {
            this.sendForm.platform = this.platformList[0].code;
            this.sendForm.accessKey = this.platformList[0].value;
            this.getSignList(
              //签名列表
              {
                platformId: this.platformList[0].id,
              }
            );
          }
        }
      });
    },
    // 点击查询
    inquire(index, key) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        gmtCreatedStart: "",
        gmtCreatedEnd: "",
      };
      if (key === "Intelligence") {
        data.activityCode = this.activityCodeTitle;
        data.longUrl = this.activitySubCodeTitle;
        if (this.intelligenceTime[0]) {
          data.gmtCreatedStart = this.intelligenceTime[0] + " 00:00:00";
          data.gmtCreatedEnd = this.intelligenceTime[1] + " 23:59:59";
        }
        // 智能短链
        this.getGroupList(data);
      }
      if (key === "free") {
        // 免费短链
        if (this.asynTime) {
          clearInterval(this.asynTime);
          this.asynTime = null;
        }
        if (this.freeTime[0]) {
          data.gmtCreatedStart = this.freeTime[0] + " 00:00:00";
          data.gmtCreatedEnd = this.freeTime[1] + " 23:59:59";
        }
        data.urlName = this.urlNameTitle;
        this.getSingleShortUrlList(data);
      }
    },
    // 点击标签页
    callback(key) {
      if (key === "Intelligence") {
        // 智能短链
        this.inquire(1, "Intelligence");
      }
      if (key === "free") {
        // 免费短链
        this.inquire(1, "free");
      }
    },
    // 点击分析--短信数量
    analysisClick(val) {
      this.analysisShow = true;
      this.groupId = val.id;
      this.sendShortName = val.activityCode;
      this.shortUrlName = val.activityCodeLabel;
      this.analysisPageNo = 1;
      let data = {
        groupId: val.id,
        pageNo: 1,
        pageSize: 10,
      };
      this.getGroupDetailList(data);
    },
    // 查询分析列表
    getGroupDetailList(data) {
      this.$http.get("/shortUrl/groupDetailList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
          });
          this.paginationAnalysis = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_Analysis(current),
          };
          this.dataAnalysis = list;
        }
      });
    },
    // 分析分页
    currentPage_Analysis(index) {
      this.analysisPageNo = index;
      let data = {
        groupId: this.groupId,
        pageNo: index,
        pageSize: 10,
      };
      this.getGroupDetailList(data);
    },
    // 刷新分析数据
    inquireAnalysis() {
      let data = {
        groupId: this.groupId,
        pageNo: this.analysisPageNo,
        pageSize: 10,
      };
      this.getGroupDetailList(data);
    },
    // 查询智能短链
    getGroupList(data) {
      this.loadingTable = true;
      this.$http
        .get("/shortUrl/groupList", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            let ids = "";
            list.forEach((item, index) => {
              item.key = index + 1;
              item.longUrlLabel = item.longUrl;
              item.isCopyShow = false;
              if (item.longUrl.length > 32) {
                item.longUrlLabel = item.longUrl.substr(0, 32) + "...";
              }
              if (item.gmtCreated) {
                item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              } else {
                item.gmtCreated = "";
              }
              if (item.activityCode === item.activitySubCode) {
                item.activityCodeLabel = item.activityCode;
              } else {
                item.activityCodeLabel =
                  item.activityCode + "-" + item.activitySubCode;
              }
              if (item.status === "CREATING") {
                ids += item.id + ",";
              }
            });
            this.dataIntelligence = list;
            if (this.asynTime) {
              clearInterval(this.asynTime);
              this.asynTime = null;
            }
            if (ids) {
              this.AsynIntelligence(ids); // 刷新智能短链列表
            }
            this.paginationIntelligence = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_Intelligence(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 智能短链分页
    currentPage_Intelligence(index) {
      this.inquire(index, "Intelligence");
    },
    // 查询免费短链
    getSingleShortUrlList(data) {
      this.loadingTable = true;
      this.$http
        .get("/shortUrl/singleShortUrlList", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
              if (item.gmtCreated) {
                item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              } else {
                item.gmtCreated = "";
              }
              item.longUrlLabel = item.longUrl;
              if (item.longUrl.length > 32) {
                item.longUrlLabel = item.longUrl.substr(0, 32) + "...";
              }
              item.isCopyShow = false;
            });
            this.paginationFree = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_free(current),
            };
            this.dataFree = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 免费短链分页
    currentPage_free(index) {
      this.inquire(index, "free");
    },
    // 选择表格数据
    onSelectChange(selectedRowKeys, selectedRows) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys);
      // console.log('selectedRows changed: ', selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.isBatchDis = true;
      if (selectedRowKeys.length > 0) {
        this.isBatchDis = false;
      }
    },
    // 点击禁用
    clickProhibit(val) {
      this.$confirm({
        title: "提示",
        content: "确定禁用短链接？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {},
        onCancel: () => {},
        class: "test",
      });
    },
    // 点击删除
    clickDelete(val) {
      this.$confirm({
        title: "提示",
        content: "确定删除短链接？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {},
        onCancel: () => {},
        class: "test",
      });
    },
    // 查询短信发送记录
    getMsgPlanList(id) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        shortUrlGroupId: id,
        smsPlanType: "BATCH_SEND",
      };
      this.$http.get("/sms/msgPlanList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          if (list && list.length > 0) {
            this.isRetransmission = true;
          }
        }
      });
    },
    // 点击发送短信
    clickSend(val) {
      this.randomNum = this.$common.randomStr(false, 12);
      this.pooiForm = {
        validNumber: val.shortUrlCount,
        moveNumber: val.cmccCount,
        unicomNumber: val.cmcuCount,
        telecomNumber: val.cmctCount,
      };
      this.isRetransmission = false;
      this.retransmission = false;
      this.longUrl = val.longUrl;
      this.maxUrlLength = val.maxUrlLength;
      this.sendForm.activitySubCode = val.activitySubCode;
      this.sendForm.activityCode = val.activityCode;
      this.shortUrlGroupId = val.id;
      this.sendShortShow = true;
      this.sendShortName = val.activityCode;
      this.sendForm.sendCount = val.shortUrlCount;
      // this.getPlatformList(); //获取平台
      this.getUserSmsAccount(); //获取账号
      this.getSignList(); //获取签名
      this.getMsgPlanList(val.id);
      this.$nextTick(() => {
        this.prefix = "none";
        let textarea = this.textarea;
        this.textarea = textarea + "#短链#";
      });
    },
    // 确定发送短信
    sendShort_submit() {
      let index = this.textarea.indexOf("#短链#");
      let lastIndex = this.textarea.lastIndexOf("#短链#");
      if (index !== lastIndex) {
        this.$message.warning("内容不能有多个短链！");
        return;
      }
      let indexNum = this.textarea.indexOf("#尾号#");
      let lastIndexNum = this.textarea.lastIndexOf("#尾号#");
      if (indexNum !== lastIndexNum) {
        this.$message.warning("内容不能有多个尾号！");
        return;
      }
      let content = this.sendForm.sign + this.textarea;
      let data = {
        signId: this.sendForm.signId,
        content: content,
        activityCode: this.sendForm.activityCode,
        longUrl: this.longUrl,
        activitySubCode: this.sendForm.activitySubCode,
        // accessKey: this.sendForm.accessKey,
        // platform: this.sendForm.platform,
        userSmsAccountId: this.sendForm.userSmsAccountId,
        operatorList: this.operatorList,
        shortUrlGroupId: this.shortUrlGroupId,
        token: this.randomNum,
      };
      if (index !== -1) {
        data.prefix = this.prefix;
      }
      if (!this.sendForm.signId) {
        this.$message.warning("签名不能为空！");
        return;
      }
      if (!this.textarea) {
        this.$message.warning("短信内容不能为空！");
        return;
      }
      if (!this.retransmission && this.isRetransmission) {
        this.$message.warning("请勾选还需要再发送一次");
        return;
      }
      if (this.checkedTiming) {
        // 选择定时发送
        if (!this.sendForm.time) {
          this.$message.warning("时间不能为空！");
          return;
        }
        data.sendTime = this.$common.transformTime(this.sendForm.time);
        this.sendLoading = true;
        this.$http
          .json_post("/sms/addMsgPlanForShortUrlGroup", data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("提交审核成功！");
              this.sendShortShow = false;
              this.sendLoading = false;
              // this.$router.push({ path: "/smmSmarketingRecords" });
            }
          });
      } else {
        this.sendLoading = true;
        this.$http
          .json_post("/sms/addMsgPlanForShortUrlGroup", data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("提交审核成功！");
              this.sendShortShow = false;
              this.sendLoading = false;
              // this.$router.push({ path: "/smmSmarketingRecords" });
            }
          });
      }
    },
    // 关闭发送短信
    sendShortClose() {
      this.sendLoading = false;
      this.isPrecise = false;
      this.isPreciseCheck = false;
      this.operatorList = [];
      this.maxUrlLength = 0;
      this.textarea = "";
      this.checkedTiming = false;
      this.isTime = false;
      this.prefix = undefined;
      this.sendForm = {
        sendCount: 0,
        activityCode: "",
        activitySubCode: "",
        sign: "",
        platform: "",
        signId: undefined,
        time: null,
      };
      this.pooiForm = {
        validNumber: 0,
        moveNumber: 0,
        unicomNumber: 0,
        telecomNumber: 0,
      };
    },
    // 选择定时发送
    onChangeTiming(e) {
      this.checkedTiming = e.target.checked;
      this.isTime = e.target.checked;
    },
    // 选择平台
    platformChange(val) {
      this.sendForm.accessKey = val;
      this.sendForm.signId = undefined;
      let platformId = "";
      this.platformList.forEach((item) => {
        if (val === item.value) {
          this.sendForm.platform = item.code;
          platformId = item.id;
        }
      });
      this.getSignList(
        //签名列表
        {
          platformId: platformId,
        }
      );
    },
    // 账号下拉框
    userSmsChange(val) {
      this.getSignList(val);
    },
    getSignList(val) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        userSmsAccountId: val,
      };
      this.$http.get("/sms/userSignInfo/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let signList = [];
          list.forEach((item) => {
            signList.push({
              sign: "【" + item.sign.trim() + "】",
              label: item.sign.trim(),
              signId: item.id,
            });
          });
          this.signList = signList;
        }
      });
    },
    // 选择签名
    sighChange(signId) {
      this.sendForm.signId = signId;
      this.signList.forEach((item) => {
        if (item.signId === signId) {
          this.sendForm.sign = item.sign;
        }
      });
    },
    // 点击新增短链接
    shortLinkClick(key) {
      this.isAddShortModal = true;
      this.shortModalTitle = "添加短链接";
      this.getDomainList();
      if (key === "Intelligence") {
        // 智能短链
        this.isZhiNengShow = true;
      }
      if (key === "free") {
        // 免费短链
        this.isZhiNengShow = false;
      }
      this.shortLinkShow = true;
      this.longUrl = "https://www.baidu.com/";
    },
    // 确定新增短链接
    shortLink_submit() {
      console.log(this.source);
      if (this.isZhiNengShow) {
        if (this.fileList.length < 1) {
          this.$message.warning("请上传标识文件");
          return;
        }
      }
      if (!this.name) {
        this.$message.warning("请输入短链名称");
        return;
      }
      if (this.name.length > 32) {
        this.$message.warning("短链名称不能大于32个字符");
        return;
      }
      if (this.activitySubCode.length > 32) {
        this.$message.warning("批次名称不能大于32个字符");
        return;
      }
      if (!this.longUrl) {
        this.$message.warning("请输入长网址链接");
        return;
      }
      if (!this.selectDomain) {
        this.$message.warning("请选择域名");
        return;
      }
      if (!this.selectGory) {
        this.$message.warning("请选择类型");
        return;
      }
      // if (!this.source) {
      //   this.$message.warning("请选择类型");
      //   return;
      // }
      // if (!this.dataSign) {
      //   this.$message.warning("请输入数据标记");
      //   return;
      // }
      let httpIndex = this.longUrl.indexOf("http://");
      let httpsIndex = this.longUrl.indexOf("https://");
      if (httpIndex === 0 || httpsIndex === 0) {
      } else {
        return;
      }
      if (!this.activitySubCode) {
        this.activitySubCode = this.name;
      }
      this.shortLinkDisabled = true;
      if (this.isZhiNengShow) {
        // 新增智能短链
        const { fileList } = this;
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append("file", file);
        });
        formData.append("activityCode", this.name);
        formData.append("activitySubCode", this.activitySubCode);
        formData.append("longUrl", this.longUrl);
        formData.append("domain", this.selectDomain);
        formData.append("category", this.selectGory);
        formData.append("source", this.source);
        formData.append("clickCallbackUrl", this.clickCallbackUrl);
        // formData.append("dataSign", this.dataSign);
        reqwest({
          url:
            location.protocol +
            process.env.VUE_APP_BASE_API +
            "/shortUrl/addGroup",
          method: "post",
          processData: false,
          crossOrigin: true,
          withCredentials: true,
          data: formData,
          success: () => {
            this.fileList = [];
            this.shortLinkShow = false;
            this.$message.success("新增成功！");
            this.inquire(1, "Intelligence");
            this.shortLinkDisabled = false;
          },
          error: (res) => {
            this.shortLinkShow = false;
            this.$message.error(JSON.parse(res.response).message);
            this.shortLinkDisabled = false;
          },
        });
      } else {
        // 新增免费短链
        let data = {
          urlName: this.name,
          longUrl: this.longUrl,
          domain: this.selectDomain,
        };
        this.$http
          .json_post("/shortUrl/addSingleShortUrl", data)
          .then((res) => {
            this.shortLinkDisabled = false;
            if (res.result === 200) {
              this.shortLinkShow = false;
              this.$message.success("新增成功！");
              this.inquire(1, "free");
            }
          })
          .catch((err) => {
            this.shortLinkDisabled = false;
          });
      }
    },
    // 关闭新增短链接
    shortLinkClose() {
      this.name = "";
      // this.dataSign = "";
      this.selectDomain = undefined;
      this.activitySubCode = "";
      this.longUrl = "https://www.baidu.com/";
      this.fileList = [];
      this.batchShow = false;
      this.urlFormat = false;
      this.shortLinkDisabled = false;
    },
    // 删除上传文件
    handleRemoveMale(file) {
      let index = this.fileList.indexOf(file);
      let newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    // 上传文件之前的钩子
    beforeUploadMale(file) {
      this.fileList = [...this.fileList, file];
      let name = this.fileList[0].name;
      let index = name.lastIndexOf(".");
      this.name = name.substring(0, index);
      return false;
    },
    // 不可选日期
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 选择时间
    onChangeIntelligenceTime(dates, dateStrings) {
      this.intelligenceTime = [dateStrings[0], dateStrings[1]];
    },
    // 选择时间
    onChangeFreeTime(dates, dateStrings) {
      this.freeTime = [dateStrings[0], dateStrings[1]];
    },
    // 查询账号
    getUserSmsAccount() {
      let data = {
        pageNo: 1,
        pageSize: 100,
        querySingleUser: true,
      };
      this.$http
        .get("/userPlatformRelation/list", data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.userLoginNameList = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
  beforeDestroy() {
    if (this.asynTime) {
      clearInterval(this.asynTime);
      this.asynTime = null;
    }
  },
};
</script>
<style>
.ant-tooltip {
  max-width: 1000px !important;
  font-size: 12px !important;
}
a[disabled="disabled"] {
  color: rgba(0, 0, 0, 0.25) !important;
}
</style>

<style scoped lang="scss">
.smsContentTemplate-c {
  width: 100%;
  text-align: right;
}
.modify-long-click {
  color: #f56c6c;
}
.textarea-content {
  .content-item {
    display: flex;
    justify-content: space-between;
  }
}
.shortLinks-button {
  margin-bottom: 10px;
  .pv-search {
    display: inline-block;
    input {
      width: 115px;
      margin-right: 10px;
    }
    span {
      width: 115px;
      margin-right: 15px;
    }
  }
  button {
    margin-right: 15px;
  }
}
.copy-class {
  color: #52c41a;
}
.icon-loading {
  margin: 0px 5px 0px 10px;
}
.number-pool {
  width: 800px;
  height: 100px;
  background: #f8f9f9;
  border: 1px solid #f2f2f2;
  padding: 16px;
  margin: 0px auto 5px;
  display: flex;
  text-align: center;
  justify-content: space-around;
  position: relative;
  .number-pool-item {
    color: #848a9f;
    .number {
      font-size: 22px;
      line-height: 50px;
    }
  }
}
.contentLR {
  display: flex;
}
.left {
  width: 660px;
}
.right {
  margin-top: 115px;
  margin-left: 50px;
  width: 240px;
  height: 450px;
  background: url("../assets/phone.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 20px;
  .bubble {
    padding: 10px;
    width: 200px;
    border-radius: 10px;
    background-color: #f0f0f0;
    position: relative;
    overflow-wrap: break-word;
  }
  .bubble::before {
    position: absolute;
    top: 40%;
    left: -20px;
    content: "";
    width: 0;
    height: 0;
  }
}
.operatorList-checkbox {
  margin-left: 15px;
}
.logUrl-class {
  display: flex;
  justify-content: space-between;
}
.actionSub-class {
  .s-url-input {
    position: relative;
    .s-url-a {
      position: absolute;
      left: 325px;
      top: 0px;
      width: 60px;
    }
  }
  .select-tag {
    width: 250px;
  }
  .template-sign {
    position: absolute;
    bottom: -4px;
    left: 0px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .template {
    position: absolute;
    bottom: -4px;
    left: 70px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .template2 {
    position: absolute;
    bottom: -4px;
    left: 120px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .template3 {
    position: absolute;
    bottom: -4px;
    left: 170px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
    // color: #3a8ee6;
  }
  .word {
    position: relative;
    display: block;
    width: 100%;
    line-height: 40px;
    text-align: right;
    font-style: normal;
    font-size: 12px;
  }
  .word i {
    color: red;
    font-style: normal;
  }
  .dropbox-ts {
    font-size: 12px;
    color: #999;
    display: block;
    line-height: 16px;
  }
}
</style>
