<template>
  <div class="smsStatusInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        短链：
        <a-input
          v-model="shortCodeSearch"
          allowClear
          placeholder="请输入短链"
        />
      </div>
      <div class="searchConditionItem">
        状态：
        <a-select
          v-model="statusSearch"
          style="width: 240px"
          placeholder="请选择状态"
          allowClear
        >
          <a-select-option v-for="i in statusList" :key="i.key">
            {{ i.value }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button type="primary" @click="addClick(1)">新 增</a-button>
      </div>
    </div>
    <!-- 短链列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="shortPagination"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="switchType" slot-scope="text, record">
        <span v-if="text === 'SWITCH_AT_FIXED_DATE'">定时替换</span>
        <span v-if="text === 'SWITCH_AT_FIXED_TIME'">定时周期替换</span>
        <span v-if="text === 'SWITCH_AT_UV_REACHED'">UV限制替换</span>
        <span v-if="text === 'SWITCH_AT_WEEK_DAY'">周期性替换</span>
        <span v-if="text === 'SWITCH_AT_INCR_UV_REACHED'">增量UV替换</span>
        <a-tooltip v-if="text === 'SWITCH_AT_INCR_UV_REACHED'">
          <template slot="title" v-if="text === 'SWITCH_AT_INCR_UV_REACHED'">
            生效时间：{{ record.effectiveDateTime }}
          </template>
          <a-icon
            v-if="text === 'SWITCH_AT_INCR_UV_REACHED'"
            type="exclamation-circle"
            style="display: inline-block; margin-left: 5px"
            theme="twoTone"
            two-tone-color="#faa755"
          />
        </a-tooltip>
      </span>
      <span slot="status" slot-scope="text">
        <a-tag color="orange" v-if="text === 'WAITING'"> 等待中 </a-tag>
        <a-tag color="green" v-if="text === 'FINISHED'"> 已完成 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <!-- <a href="javascript:;" @click="modifyClick(record)">回滚</a>
        <a-divider type="vertical" /> -->
        <a href="javascript:;" @click="updateRecord(record)">更新记录</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addShow"
      :maskClosable="false"
      :afterClose="addClose"
      width="800px"
    >
      <a-form>
        <a-form-item
          label="短链编码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            v-model="shortCode"
            placeholder="请输入短链编码"
            :disabled="title == '编辑'"
          />
        </a-form-item>
        <a-form-item
          label="过期时间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            v-model="expireDateTime"
            placeholder="请选择过期时间"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          label="替换方式:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="switchType"
            style="width: 100%"
            placeholder="请选择替换方式"
            @change="selectChange"
            :disabled="title == '编辑'"
          >
            <a-select-option v-for="item in switchList" :key="item.key">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="目标长链:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType !== 'SWITCH_AT_WEEK_DAY'"
        >
          <a-input v-model="targetLongUrl" placeholder="请输入目标长链" />
        </a-form-item>
        <a-form-item
          label="周一"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_WEEK_DAY'"
        >
          UV数：<a-input
            v-model="switchAtWeekDayConfig.itemList[0].uv"
            placeholder="请输入UV数"
            style="width: 30%"
          />
          <span style="display: inline-block; width: 13px"></span>
          长链：<a-input
            v-model="switchAtWeekDayConfig.itemList[0].longUrl"
            placeholder="请输入长链"
            style="width: 48%"
          />
        </a-form-item>
        <a-form-item
          label="周二"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_WEEK_DAY'"
        >
          UV数：<a-input
            v-model="switchAtWeekDayConfig.itemList[1].uv"
            placeholder="请输入UV数"
            style="width: 30%"
          />
          <span style="display: inline-block; width: 13px"></span>
          长链：<a-input
            v-model="switchAtWeekDayConfig.itemList[1].longUrl"
            placeholder="请输入长链"
            style="width: 48%"
          />
        </a-form-item>
        <a-form-item
          label="周三"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_WEEK_DAY'"
        >
          UV数：<a-input
            v-model="switchAtWeekDayConfig.itemList[2].uv"
            placeholder="请输入UV数"
            style="width: 30%"
          />
          <span style="display: inline-block; width: 13px"></span>
          长链：<a-input
            v-model="switchAtWeekDayConfig.itemList[2].longUrl"
            placeholder="请输入长链"
            style="width: 48%"
          />
        </a-form-item>
        <a-form-item
          label="周四"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_WEEK_DAY'"
        >
          UV数：<a-input
            v-model="switchAtWeekDayConfig.itemList[3].uv"
            placeholder="请输入UV数"
            style="width: 30%"
          />
          <span style="display: inline-block; width: 13px"></span>
          长链：<a-input
            v-model="switchAtWeekDayConfig.itemList[3].longUrl"
            placeholder="请输入长链"
            style="width: 48%"
          />
        </a-form-item>
        <a-form-item
          label="周五"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_WEEK_DAY'"
        >
          UV数：<a-input
            v-model="switchAtWeekDayConfig.itemList[4].uv"
            placeholder="请输入UV数"
            style="width: 30%"
          />
          <span style="display: inline-block; width: 13px"></span>
          长链：<a-input
            v-model="switchAtWeekDayConfig.itemList[4].longUrl"
            placeholder="请输入长链"
            style="width: 48%"
          />
        </a-form-item>
        <a-form-item
          label="周六"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_WEEK_DAY'"
        >
          UV数：<a-input
            v-model="switchAtWeekDayConfig.itemList[5].uv"
            placeholder="请输入UV数"
            style="width: 30%"
          />
          <span style="display: inline-block; width: 13px"></span>
          长链：<a-input
            v-model="switchAtWeekDayConfig.itemList[5].longUrl"
            placeholder="请输入长链"
            style="width: 48%"
          />
        </a-form-item>
        <a-form-item
          label="周日"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_WEEK_DAY'"
        >
          UV数：<a-input
            v-model="switchAtWeekDayConfig.itemList[6].uv"
            placeholder="请输入UV数"
            style="width: 30%"
          />
          <span style="display: inline-block; width: 13px"></span>
          长链：<a-input
            v-model="switchAtWeekDayConfig.itemList[6].longUrl"
            placeholder="请输入长链"
            style="width: 48%"
          />
        </a-form-item>
        <a-form-item
          label="UV数:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="
            switchType == 'SWITCH_AT_UV_REACHED' ||
            switchType == 'SWITCH_AT_INCR_UV_REACHED'
          "
        >
          <a-input v-model="switchConfig" placeholder="请输入UV数" />
        </a-form-item>
        <a-form-item
          label="生效时间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_INCR_UV_REACHED'"
        >
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            v-model="effective_date_time"
            placeholder="请选择生效时间"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          label="日期:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_FIXED_DATE'"
        >
          <!-- <a-date-picker
            show-time
            placeholder="请选择日期"
            @change="onChangeDate"   effective_date_time
          /> -->
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            v-model="switchConfig"
            placeholder="请选择日期"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          label="时间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="switchType == 'SWITCH_AT_FIXED_TIME'"
        >
          <a-time-picker
            :default-open-value="moment('12:00:00', 'HH:mm:ss')"
            v-model="switchConfig"
          />
          <!-- <a-time-picker @change="timeChange" :value="switchConfig" /> -->
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 更新记录 -->
    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      :afterClose="updateClose"
      width="900px"
    >
      <a-table
        :columns="logColumns"
        :dataSource="logTableDate"
        :pagination="logPagination"
        size="middle"
        :loading="loadingTable"
      >
        <span slot="switchType" slot-scope="text">
          <span v-if="text === 'SWITCH_AT_FIXED_DATE'">定时替换</span>
          <span v-if="text === 'SWITCH_AT_FIXED_TIME '">定时周期替换</span>
          <span v-if="text === 'SWITCH_AT_UV_REACHED'">UV限制替换</span>
          <span v-if="text === 'SWITCH_AT_WEEK_DAY'">周期性替换</span>
          <span v-if="text === 'SWITCH_AT_INCR_UV_REACHED'">增量UV替换</span>
        </span>
        <span slot="status" slot-scope="text">
          <a-tag color="red" v-if="!text"> 失败 </a-tag>
          <a-tag color="green" v-if="text"> 成功 </a-tag>
        </span>
        <!-- <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="modifyClick(record)">修改</a
          ><a-divider type="vertical" />
          <a href="javascript:;" @click="deleteClick(record)" style="color: red"
            >删除</a
          >
        </span> -->
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "shortUrlReplace",
  data() {
    return {
      title: "",
      tableNum: undefined,
      addShow: false,
      shortId: "",
      shortCode: "",
      targetLongUrl: "",
      expireDateTime: "",
      switchType: "SWITCH_AT_FIXED_DATE",
      switchConfig: "",
      effective_date_time: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "短链编码",
          dataIndex: "shortCode",
        },
        {
          title: "目标长链",
          dataIndex: "targetLongUrl",
        },
        {
          title: "替换方式",
          dataIndex: "switchType",
          scopedSlots: { customRender: "switchType" },
        },
        {
          title: "配置内容",
          dataIndex: "switchConfig",
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        // {
        //   title: "状态",
        //   dataIndex: "clickCallbackLabel",
        // },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "过期时间",
          dataIndex: "expireDateTime",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      logPagination: {},
      shortPagination: {},
      logColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "短链编码",
          dataIndex: "shortCode",
        },
        {
          title: "长链",
          dataIndex: "longUrl",
        },
        {
          title: "替换方式",
          dataIndex: "switchType",
          scopedSlots: { customRender: "switchType" },
        },
        {
          title: "状态",
          dataIndex: "success",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
      ],
      logTableDate: [],
      currentPage: 1,
      logPage: 1,
      configId: "",
      syncLoading: null,
      activityCode: "",
      loading: false,
      loadingTable: false,
      switchList: [
        {
          key: "SWITCH_AT_FIXED_DATE",
          value: "定时替换",
        },
        {
          key: "SWITCH_AT_FIXED_TIME",
          value: "定时周期替换",
        },
        {
          key: "SWITCH_AT_UV_REACHED",
          value: "UV限制替换",
        },
        {
          key: "SWITCH_AT_INCR_UV_REACHED",
          value: "增量UV替换",
        },
        {
          key: "SWITCH_AT_WEEK_DAY",
          value: "周期性替换",
        },
      ],
      shortCodeSearch: "",
      statusSearch: undefined,
      statusList: [
        {
          key: "WAITING",
          value: "等待中",
        },
        {
          key: "FINISHED",
          value: "已完成",
        },
      ],
      updateShow: false,
      switchAtWeekDayConfig: {
        itemList: [
          { dayOfWeek: 1, longUrl: "", uv: "0" },
          { dayOfWeek: 2, longUrl: "", uv: "0" },
          { dayOfWeek: 3, longUrl: "", uv: "0" },
          { dayOfWeek: 4, longUrl: "", uv: "0" },
          { dayOfWeek: 5, longUrl: "", uv: "0" },
          { dayOfWeek: 6, longUrl: "", uv: "0" },
          { dayOfWeek: 7, longUrl: "", uv: "0" },
        ],
      },
    };
  },
  computed: {
    prevDisabled() {
      if (this.currentPage === 1) {
        return true;
      }
      return false;
    },
    nextDisabled() {
      if (this.tableDataSource.length < 10) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击修改
    modifyClick(val) {
      this.shortId = val.id;
      this.shortCode = val.shortCode;
      this.expireDateTime = val.expireDateTime;
      this.switchType = val.switchType;
      this.targetLongUrl = val.targetLongUrl;
      this.switchAtWeekDayConfig = val.switchAtWeekDayConfig;
      this.switchConfig = val.switchConfig;
      this.effective_date_time = val.effectiveDateTime;
      this.addShow = true;
      this.title = "编辑";
      if (val.switchType == "SWITCH_AT_FIXED_TIME") {
        this.switchConfig = "2022-02-02 " + val.switchConfig;
      }
    },
    // //  确定修改
    // update_submit() {
    //   let data = {
    //     id: this.shortId,
    //     url: this.url,
    //     shortCode: this.shortCodeLabel,
    //   };
    //   this.loading = true;
    //   this.$http
    //     .json_post("/shortUrl/update", data)
    //     .then((res) => {
    //       this.loading = false;
    //       if (res.result === 200) {
    //         this.$message.success("修改成功");
    //         this.updateShow = false;
    //         this.inquire();
    //       }
    //     })
    //     .catch((err) => {
    //       this.loading = false;
    //     });
    // },
    // //  关闭修改
    // updateClose() {
    //   this.url = "";
    //   this.shortId = "";
    //   this.shortCodeLabel = "";
    // },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        status: this.statusSearch,
        shortCode: this.shortCodeSearch,
      };
      this.getSmsStatusInfo(data);
    },
    // 查询点击数据列表
    getSmsStatusInfo(data) {
      this.loadingTable = true;
      this.$http
        .get("/hotSwitch/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              if (item.switchType == "SWITCH_AT_WEEK_DAY") {
                item.switchConfig = "";
              }
            });
            this.tableDataSource = list;
            this.shortPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.shortChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 管理分页
    shortChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // // 管理分页
    // shortChangePage(key) {
    //   if (key === "prev") {
    //     this.currentPage--;
    //   }
    //   if (key === "next") {
    //     this.currentPage++;
    //   }
    //   this.inquire(this.currentPage);
    // },
    // 点击新增
    addClick() {
      this.title = "新增";
      this.addShow = true;
      var dateTime = new Date();
      dateTime = dateTime.setFullYear(dateTime.getFullYear() + 1);
      dateTime = new Date(dateTime);
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      // let dateTime = new Date();
      dateTime.setHours(0);
      dateTime.setMinutes(0);
      dateTime.setSeconds(0);
      dateTime.setMilliseconds(0);
      this.expireDateTime = moment(dateTime, "YYYY-MM-DD HH:mm:ss");
      if (
        this.switchType == "SWITCH_AT_FIXED_DATE"
        // ||
        // this.switchType == "SWITCH_AT_FIXED_TIME"
      ) {
        let dateTime = new Date();
        dateTime.setHours(12);
        dateTime.setMinutes(0);
        dateTime.setSeconds(0);
        dateTime.setMilliseconds(0);
        this.switchConfig = moment(dateTime, "YYYY-MM-DD HH:mm:ss");
      }
      if (this.switchType == "SWITCH_AT_FIXED_TIME") {
        let dateTime = new Date();
        dateTime.setHours(12);
        dateTime.setMinutes(0);
        dateTime.setSeconds(0);
        dateTime.setMilliseconds(0);
        this.switchConfig = moment(dateTime, "HH:mm:ss");
      }
    },
    // 关闭新增
    addClose() {
      this.shortCode = "";
      this.targetLongUrl = "";
      this.expireDateTime = "";
      this.switchType = "SWITCH_AT_FIXED_DATE";
      this.switchConfig = "";
    },
    selectChange(val) {
      if (val == "SWITCH_AT_FIXED_DATE") {
        let dateTime = new Date();
        dateTime.setHours(12);
        dateTime.setMinutes(0);
        dateTime.setSeconds(0);
        dateTime.setMilliseconds(0);
        this.switchConfig = moment(dateTime, "YYYY-MM-DD HH:mm:ss");
      } else if (this.switchType == "SWITCH_AT_FIXED_TIME") {
        let dateTime = new Date();
        dateTime.setHours(12);
        dateTime.setMinutes(0);
        dateTime.setSeconds(0);
        dateTime.setMilliseconds(0);
        this.switchConfig = moment(dateTime, "HH:mm:ss");
        console.log(this.switchConfig);
      } else if (val == "SWITCH_AT_INCR_UV_REACHED") {
        let dateTime = new Date();
        this.switchConfig = "";
        this.effective_date_time = moment(dateTime, "YYYY-MM-DD HH:mm:ss");
      } else {
        this.switchConfig = "";
        // this.effective_date_time = "";
      }
    },
    // 新增确定
    add_submit() {
      let data = {
        shortCode: this.shortCode.trim(),
        targetLongUrl: this.targetLongUrl.trim(),
        expireDateTime: this.$common.transformTime(this.expireDateTime),
        switchType: this.switchType,
        // switchConfig: this.switchConfig,
      };
      if (data.switchType == "SWITCH_AT_FIXED_DATE") {
        data.switchConfig = this.$common.transformTime(this.switchConfig);
      } else if (data.switchType == "SWITCH_AT_FIXED_TIME") {
        data.switchConfig = this.$common
          .transformTime(this.switchConfig)
          .split(" ")[1];
      } else if (data.switchType == "SWITCH_AT_INCR_UV_REACHED") {
        data.effectiveDateTime = this.$common.transformTime(
          this.effective_date_time
        );
        data.switchConfig = this.switchConfig;
      } else if (data.switchType == "SWITCH_AT_WEEK_DAY") {
        data.switchAtWeekDayConfig = this.switchAtWeekDayConfig;
      } else {
        data.switchConfig = this.switchConfig;
      }
      console.log(data);
      if (
        data.switchType == "SWITCH_AT_WEEK_DAY" ||
        data.targetLongUrl.indexOf("http") != -1
      ) {
        if (this.title === "新增") {
          console.log(data);
          this.$http
            .json_post("/hotSwitch/addHotSwitchConfig", data)
            .then((res) => {
              if (res.result == 200) {
                this.$message.success("新增成功");
                this.addShow = false;
                this.inquire();
              }
            });
        } else if (this.title === "编辑") {
          data.id = this.shortId;
          this.$http
            .json_post("/hotSwitch/modifyHotSwitchConfig", data)
            .then((res) => {
              this.loading = false;
              if (res.result === 200) {
                this.$message.success("修改成功");
                this.addShow = false;
                this.inquire();
              }
            });
        }
      } else {
        this.$message.warning("长链必须是http开头");
      }
    },
    // 更新记录?pageNo=xx&pageSize=xx&configId=xx
    updateRecord(val) {
      this.configId = val.id;
      this.getLogList(1);
      this.updateShow = true;
    },
    getLogList(index) {
      if (index) {
        this.logPage = index;
      }
      let data = {
        configId: this.configId,
        pageNo: this.logPage,
        pageSize: 10,
      };
      this.$http.get("/hotSwitch/logList", data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.logTableDate = list;
          this.logPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.logChangePage(current),
          };
        }
      });
    },
    // 管理分页
    logChangePage(index) {
      this.logPage = index;
      this.getLogList(index);
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.shortCode + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .json_post("/hotSwitch/deleteHotSwitchConfig", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire(1);
              }
            });
        },
        onCancel() {},
      });
    },
    onChange(value, dateString) {
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
      this.expireDateTime = dateString;
    },
    onChangeDate(value, dateString) {
      this.switchConfig = dateString;
    },
    updateClose() {},
    moment,
    timeChange(time, timeString) {
      console.log(time, timeString);
      this.switchConfig = time;
    },
  },
};
</script>
