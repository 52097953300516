var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container"},[_c('div',{staticClass:"login-content"},[_c('div',{staticClass:"title"},[_vm._v("短信管理平台")]),_c('div',{staticClass:"login"},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'userName',
        { rules: [{ required: true, message: '请输入账户名' }] }
      ]),expression:"[\n        'userName',\n        { rules: [{ required: true, message: '请输入账户名' }] }\n      ]"}],attrs:{"size":"large","placeholder":"请输入账户名"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        { rules: [{ required: true, message: '请输入密码' }] }
      ]),expression:"[\n        'password',\n        { rules: [{ required: true, message: '请输入密码' }] }\n      ]"}],attrs:{"size":"large","type":"password","placeholder":"请输入密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%","margin-top":"24px"},attrs:{"loading":_vm.logging,"type":"primary","html-type":"submit","size":"large"}},[_vm._v("登 录")])],1)],1)],1)]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"record-number"},[_c('a',{attrs:{"href":"http://www.beian.miit.gov.cn"}},[_vm._v("湘ICP备17021768号")])])
}]

export { render, staticRenderFns }