<template>
  <div class="longUrlCheckTemplate">
    <div class="searchButton">
      <a-button type="primary" @click="query(1)">查询</a-button>
      <a-button type="primary" @click="add">新增</a-button>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="longUrlTitle">
        匹配规则
        <a-tooltip placement="left">
          <div slot="title">
            使用说明: 场景1: 只有某个参数会变,还是数字, 推荐使用正则匹配
            <br />
            https://www.smyfinancial.com/cms/qczj?qd=dx_szlmxx_szlmxx_aa_1&cy=dx_szlmxx_szlmxx_aa_1_cy390&scd=1&nc=1&nlwl=1&prefill=gt
            <br />
            https://www.smyfinancial.com/cms/qczj?qd=dx_szlmxx_szlmxx_aa_1&cy=dx_szlmxx_szlmxx_aa_1_cy391&scd=1&nc=1&nlwl=1&prefill=gt
            <br />
            正则:https://www.smyfinancial.com/cms/qczj\?qd=dx_szlmxx_szlmxx_aa_1&cy=dx_szlmxx_szlmxx_aa_1_cy\d+&scd=1&nc=1&nlwl=1&prefill=gt
            <br />
            场景2: 长链尾部会变, 没有参数, 推荐使用正则匹配
            <br />
            https://work.weixin.qq.com/ca/cawcde\abcd
            <br />
            https://work.weixin.qq.com/ca/cawcde\efgh
            <br />
            正则:https://work.weixin.qq.com/ca/cawcde\w+
          </div>
          <a-icon
            type="question-circle"
            style="color: #1879ff; font-size: 14px"
          />
        </a-tooltip>
      </span>
      <span slot="type" slot-scope="text, record">
        {{
          text == "REGEX_MATCH"
            ? "正则匹配"
            : text == "PARAM_MATCH"
            ? "参数匹配"
            : text == "WHITE"
            ? "白名单"
            : ""
        }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>

    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input v-model="name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="匹配规则:">
          <a-select v-model="type" style="width: 30%">
            <a-select-option
              v-for="i in typeList"
              :key="i.id"
              :value="i.value"
              >{{ i.label }}</a-select-option
            >
          </a-select>
          <a-input
            v-model="longUrl"
            placeholder="请输入匹配规则"
            v-if="!(type == 'WHITE' && title == '新增')"
            style="width: 68%; margin-left: 5px"
          />
        </a-form-item>
        <a-form-item v-if="type == 'WHITE' && title == '新增'">
          <span slot="label">
            导入长链
            <a-tooltip placement="left">
              <div slot="title">一行一条链接，支持txt和csv</div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px"
              />
            </a-tooltip>
          </span>
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
        <a-form-item label="校验:" v-else>
          <a-input
            v-model="checkLongUrl"
            placeholder="请输入长链"
            @blur="checkMatch"
          />
          <span
            class="dropbox-ts"
            :style="{ color: longUrlExistYesOrNo ? '#52c41a' : 'red' }"
            v-if="urlFormat"
          >
            {{ urlFormatText }}
          </span>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "longUrlCheckTemplate",
  data() {
    return {
      title: "",
      longUrl: "",
      checkLongUrl: "",
      urlFormat: false,
      urlFormatText: "",
      longUrlExistYesOrNo: false,
      name: "",
      type: "PARAM_MATCH",
      id: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          dataIndex: "longUrl",
          scopedSlots: { title: "longUrlTitle" },
        },
        {
          title: "类型",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      selectedRowKeys: [],
      typeList: [
        { id: 1, label: "正则匹配", value: "REGEX_MATCH" },
        { id: 2, label: "参数匹配", value: "PARAM_MATCH" },
        { id: 3, label: "白名单", value: "WHITE" },
      ],
      fileList: [],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
      };
      this.loadingTable = true;
      this.$http
        .get("/longUrlCheck/templatePage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.id = val.id;
      this.longUrl = val.longUrl;
      this.checkLongUrl = "";
      this.urlFormat = false;
      this.name = val.name;
      this.type = val.type;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.longUrl = "";
      this.checkLongUrl = "";
      this.urlFormat = false;
      this.name = "";
      this.type = "PARAM_MATCH";
      this.fileList.splice(0);
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    // 确定新增
    add_submit() {
      let data = {
        longUrl: this.longUrl,
        name: this.name,
        type: this.type,
      };
      if (this.title === "新增") {
        if (this.type == "WHITE") {
          delete data.longUrl;
          data.file = this.fileList[0];
        }
        this.$http.files("/longUrlCheck/addTemplate", data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.id;
        this.$http.json_post("/longUrlCheck/editTemplate", data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.id = "";
      this.longUrl = "";
      this.checkLongUrl = "";
    },
    checkMatch() {
      if (!this.checkLongUrl) {
        return;
      }
      let httpIndex = this.checkLongUrl.indexOf("http://");
      let httpsIndex = this.checkLongUrl.indexOf("https://");
      if (httpIndex === 0 || httpsIndex === 0) {
        this.urlFormat = false;
        let data = {
          longUrlTemplate: this.longUrl,
          longUrl: this.checkLongUrl,
          type: this.type,
        };
        this.$http.json_post("/longUrlCheck/checkMatch", data).then((res) => {
          if (res.result === 200) {
            this.urlFormat = true;
            this.longUrlExistYesOrNo = res.data;
            this.urlFormatText = this.longUrlExistYesOrNo
              ? "校验通过"
              : "校验失败";
          }
        });
      } else {
        this.urlFormatText = "长网址链接格式不正确";
        this.urlFormat = true;
        this.longUrlExistYesOrNo = false;
      }
    },
    batchDeleteClick(val) {
      let data = {
        ids: this.selectedRowKeys.join(","),
      };
      this.$http.json_post("/longUrlCheck/deleteTemplate", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        ids: val.id,
      };
      this.$http.json_post("/longUrlCheck/deleteTemplate", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style scoped>
.dropbox-ts {
  font-size: 12px;
  color: #999;
  display: block;
  line-height: 16px;
}
</style>
