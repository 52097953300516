<template>
  <div class="dataClick">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="点击数据">
        <router-view name="three" v-if="active == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane key="2" tab="UV数监控">
        <router-view name="three" v-if="active == '2'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "dataClick",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/dataClick/clickDataSource") {
          this.active = "1";
        } else if (newVal == "/dataClick/urlUvMonitor") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/dataClick/clickDataSource") return;
          this.$router.replace("/dataClick/clickDataSource");
        }
        if (newVal === "2") {
          if (this.$route.path == "/dataClick/urlUvMonitor") return;
          this.$router.replace("/dataClick/urlUvMonitor");
        }
      },
    },
  },
};
</script>
