<template>
  <div class="smsStatusInfo">
    <div class="searchButton">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
      <a-button type="primary" @click="addClick">新 增</a-button>
    </div>
    <!-- 短链列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="shortPagination"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="enabled" slot-scope="text">
        <a-tag v-if="text" color="green">启用</a-tag>
        <a-tag v-else color="red">禁用</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="getUserList(record)">用户关联列表</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 修改短链 -->
    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      :afterClose="updateClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="域名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="domain" placeholder="请输入域名" />
        </a-form-item>
        <a-form-item
          label="可用状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="userTitle"
      v-model="userVisible"
      :maskClosable="false"
      class="action-class"
      width="1200px"
    >
      <div style="margin-bottom: 20px">
        <a-button type="primary" @click="addUser()">新增</a-button>
      </div>
      <a-table
        :columns="userColumns"
        :dataSource="userList"
        :pagination="userPagination"
        :rowKey="(record) => record.id"
        size="middle"
      >
        <span slot="enabled" slot-scope="text, record">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="record.enabled"
            @change="enabledChange($event, record.id)"
          />
        </span>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteUser(record)"
          >
            <template slot="title">是否确认删除</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="userVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="添加用户"
      v-model="addUserVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="用户名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="userLoginName" placeholder="请输入用户名称" />
        </a-form-item>
        <a-form-item
          label="可用状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addUserVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addUser_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "domainInfo",
  data() {
    return {
      tableNum: 0,
      updateShow: false,
      updateId: "",
      domain: "",
      enabled: true,
      title: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "域名",
          dataIndex: "domain",
        },
        {
          title: "状态",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      shortPagination: {},
      currentPage: 1,
      syncLoading: null,
      activityCode: "",
      loading: false,
      loadingTable: false,
      userVisible: false,
      domainInfoId: "",
      userLoginName: "",
      addUserVisible: false,
      userList: [],
      userColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "用户名称",
          dataIndex: "userLoginName",
        },
        {
          title: "是否启用",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      userPagination: {},
      userPageNo: 1,
      userTitle: "",
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    enabledChange(enabled, id) {
      let data = {
        enabled,
        id,
      };
      this.$http
        .json_post("/domainInfo/enableUserRelation", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
          }
        });
    },
    addUser() {
      this.userLoginName = "";
      this.enabled = true;
      this.addUserVisible = true;
    },
    addUser_submit() {
      let data = {
        userLoginName: this.userLoginName,
        enabled: this.enabled,
        domainInfoId: this.domainInfoId,
      };
      this.$http
        .json_post("/domainInfo/insertUserRelation", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("添加成功");
            this.addUserVisible = false;
            this.getUserList();
          }
        });
    },
    deleteUser(val) {
      this.$http
        .json_post("/domainInfo/deleteUserRelation", { id: val.id })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("删除成功");
            this.getUserList();
          }
        });
    },
    getUserList(val, index) {
      if (val) {
        this.domainInfoId = val.id;
        this.userPageNo = 1;
        this.userTitle = val.domain + "用户关联列表";
      }
      if (index) {
        this.userPageNo = index;
      }
      let data = {
        pageNo: this.userPageNo,
        pageSize: 10,
        domainInfoId: this.domainInfoId,
      };
      this.$http.get("/domainInfo/userRelationList", data).then((res) => {
        if (res.result == 200) {
          this.userList = res.data.records;
          this.userPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.userChangePage(current),
          };
          this.userVisible = true;
        }
      });
    },
    userChangePage(index) {
      this.getUserList(null, index);
    },
    // 点击修改
    modifyClick(val) {
      this.updateId = val.id;
      this.domain = val.domain;
      this.enabled = val.enabled;
      this.updateShow = true;
      this.title = "编辑";
    },
    //  确定修改
    update_submit() {
      let data = {
        domain: this.domain,
        enabled: this.enabled,
      };
      this.loading = true;
      if (this.title === "新增") {
        this.$http
          .json_post("/domainInfo/insert", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.updateShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else if (this.title === "编辑") {
        data.id = this.updateId;
        this.$http
          .json_post("/domainInfo/update", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.updateShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    //  关闭修改
    updateClose() {
      this.domain = "";
      this.enabled = true;
    },
    // 点击查询
    inquire(index) {
      let data = {
        pageNo: index || this.currentPage,
        pageSize: 10,
        // tableNum: this.tableNum,
        // activityCode: this.activityCode,
      };
      this.getSmsStatusInfo(data);
    },
    // 查询点击数据列表
    getSmsStatusInfo(data) {
      this.loadingTable = true;
      this.$http
        .get("/domainInfo/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              item.clickCallbackLabel = "否";
              if (item.clickCallback) {
                item.clickCallbackLabel = "是";
              }
              if (item.activityCode === item.activitySubCode) {
                item.activityCodeLabel = item.activityCode;
              } else {
                item.activityCodeLabel =
                  item.activityCode + " ( " + item.activitySubCode + " )";
              }
            });
            this.tableDataSource = list;
            this.shortPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.shortChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 管理分页
    shortChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 点击新增
    addClick() {
      this.title = "新增";
      this.updateShow = true;
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.domain + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/domainInfo/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
