import http from "./axios";

export const queryPage = (data) => {
  return http.get("/longUrlGroup/groupPage", data);
};

export const groupDetail = (data) => {
  return http.get("/longUrlGroup/groupDetail", data);
};

export const addData = (data) => {
  return http.json_post("/longUrlGroup/addGroup", data);
};

export const editData = (data) => {
  return http.json_post("/longUrlGroup/editGroup", data);
};

export const updateEnable = (data) => {
  return http.json_post("/longUrlGroup/updateEnable", data);
};

export const editExpire = (data) => {
  return http.json_post("/longUrlGroup/editExpire", data);
};

export const itemEditExpire = (data) => {
  return http.json_post("/longUrlGroup/itemEditExpire", data);
};
