<template>
  <div class="clickCallback">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="点击回调">
        <router-view name="three" v-if="active == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane key="2" tab="用户关联">
        <router-view name="three" v-if="active == '2'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "clickCallback",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/clickCallback/clickCallbackUrl") {
          this.active = "1";
        } else if (newVal == "/clickCallback/clickCallbackUrlUserRelation") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/clickCallback/clickCallbackUrl") return;
          this.$router.replace("/clickCallback/clickCallbackUrl");
        }
        if (newVal === "2") {
          if (this.$route.path == "/clickCallback/clickCallbackUrlUserRelation")
            return;
          this.$router.replace("/clickCallback/clickCallbackUrlUserRelation");
        }
      },
    },
  },
};
</script>
