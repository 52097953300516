<template>
  <div class="smsStatusInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        短链：
        <a-input v-model="shortUrl" placeholder="请输入短链" />
      </div>
      <div class="searchConditionItem">
        长链：
        <a-input v-model="longUrl" placeholder="请输入长链" />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button type="primary" @click="addClick">新 增</a-button>
      </div>
    </div>
    <!-- 长链列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="shortPagination"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="enabled" slot-scope="text">
        <a-tag v-if="text" color="green">启用</a-tag>
        <a-tag v-else color="red">禁用</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a
        ><a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 修改长链 -->
    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      :afterClose="updateClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="短链:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="shortUrlAdd" placeholder="请输入短链" />
        </a-form-item>
        <a-form-item
          label="长链:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="longUrlAdd" placeholder="请输入长链" />
        </a-form-item>
        <a-form-item
          label="值:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="value" placeholder="请输入值" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "longUrlList",
  data() {
    return {
      shortUrl: "",
      updateShow: false,
      updateId: "",
      shortUrlAdd: "",
      longUrlAdd: "",
      value: "",
      title: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "短链",
          dataIndex: "shortUrl",
        },
        {
          title: "长链",
          dataIndex: "longUrl",
        },
        {
          title: "值",
          dataIndex: "value",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      shortPagination: {},
      currentPage: 1,
      syncLoading: null,
      longUrl: "",
      loading: false,
      loadingTable: false,
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击修改
    modifyClick(val) {
      this.updateId = val.id;
      this.shortUrlAdd = val.shortUrl;
      this.longUrlAdd = val.longUrl;
      this.value = val.value;
      this.updateShow = true;
      this.title = "编辑";
    },
    //  确定修改
    update_submit() {
      let data = {
        shortUrl: this.shortUrlAdd,
        longUrl: this.longUrlAdd,
        value: this.value,
      };
      this.loading = true;
      if (this.title === "新增") {
        this.$http
          .json_post("/longUrlValue/add", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.updateShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else if (this.title === "编辑") {
        data.id = this.updateId;
        this.$http
          .json_post("/longUrlValue/edit", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.updateShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    //  关闭修改
    updateClose() {
      this.shortUrlAdd = "";
      this.longUrlAdd = "";
      this.value = "";
    },
    // 点击查询
    inquire(index) {
      let data = {
        pageNo: index || this.currentPage,
        pageSize: 10,
        shortUrl: this.shortUrl,
        longUrl: this.longUrl,
      };
      this.getSmsStatusInfo(data);
    },
    // 查询点击数据列表
    getSmsStatusInfo(data) {
      this.loadingTable = true;
      this.$http
        .get("/longUrlValue/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.shortPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.shortChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 管理分页
    shortChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 点击新增
    addClick() {
      this.title = "新增";
      this.updateShow = true;
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.longUrl + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/longUrlValue/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>
