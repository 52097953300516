<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view name="first"/>
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");
export default {
  data() {
    return {
      zh_CN
    };
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  height: 100%;
  .search-m {
    margin-bottom: 20px;
    .search-m-1 {
      display: inline-block;
      width: 350px;
      .ant-input-affix-wrapper {
        width: 250px;
      }
    }
  }
  .search-botton {
    margin-bottom: 20px;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.searchCondition {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .searchConditionItem {
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
    align-items: center;
    span {
      white-space: nowrap;
    }
    .ant-input-affix-wrapper {
      width: 240px;

      .ant-input {
        width: 100%;
      }
    }
    .ant-input {
      width: 240px;
    }

    .ant-select {
      width: 240px;
    }
    .ant-calendar-picker-input {
      width: 100% !important;
    }
  }
}

.searchButton {
  button {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.ant-btn-link {
  padding: 0 !important;
}
a[disabled] {
  cursor: not-allowed !important;
  pointer-events: auto !important;
  color: #00000040 !important;
}
.ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ededed;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #7c7c7c;
  }
}
.ant-popover-message-title {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
