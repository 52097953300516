import http from './axios'

// 查询列表
const queryPage = ({
    url,
    pageNo,
    pageSize
}) => {
    return http.get('/uvMonitor/page', {
        url,
        pageNo,
        pageSize
    })
}

// 添加
const addData = ({
    isShortUrl,
    url,
    uv,
}) => {
    return http.json_post('/uvMonitor/add', {
        isShortUrl,
        url,
        uv,
    })
}

const edit = ({
    isShortUrl,
    url,
    uv,
    id
}) => {
    return http.json_post('/uvMonitor/edit', {
        isShortUrl,
        url,
        uv,
        id
    })
}

// 删除
const deleteData = ({
    id
}) => {
    return http.json_post('/uvMonitor/delete', {
        id,
    })
}

export default {
    deleteData,
    queryPage,
    addData,
    edit
}