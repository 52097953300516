import http from "./axios";

// 查询列表
const queryPage = (data) => {
  return http.get("/clickCallback/page", data);
};

// 添加
const addData = (data) => {
  return http.json_post("/clickCallback/add", data);
};

// 编辑
const editData = (data) => {
  return http.json_post("/clickCallback/edit", data);
};

// 删除
const deleteData = (data) => {
  return http.json_post("/clickCallback/delete", data);
};

export default {
  queryPage,
  addData,
  editData,
  deleteData,
};
