<template>
  <div class="smsStatusInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        短链：
        <a-input allowClear v-model="shortUrl" placeholder="请输入短链" />
      </div>
      <div class="searchConditionItem">
        日期：
        <a-range-picker
          :disabledDate="disabledDate"
          :defaultValue="[moment().startOf('month'), moment().endOf('day')]"
          :ranges="{
            今天: [moment(), moment()],
            本月: [moment().startOf('month'), moment().endOf('day')],
          }"
          format="YYYY-MM-DD"
          @change="onChangeIntelligenceTime"
        />
      </div>
      <div class="searchConditionItem">
        来源：
        <a-input allowClear v-model="source" placeholder="请输入来源" />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button type="primary" @click="addClick">新 增</a-button>
      </div>
    </div>
    <!-- 短链列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="shortPagination"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          title="确定要重新统计吗?"
          ok-text="是"
          cancel-text="否"
          @confirm="modifyClick(record)"
          :disabled="record.date !== disTime"
        >
          <a href="javascript:;" :disabled="record.date !== disTime"
            >重新统计</a
          >
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增短链 -->
    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      :afterClose="updateClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="短链:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="shortUrlAdd" placeholder="p5t.cn/xxx" />
        </a-form-item>
        <a-form-item
          label="来源:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="sourceAdd" placeholder="请输入来源" />
        </a-form-item>
        <a-form-item
          label="日期:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-date-picker
            format="YYYY-MM-DD"
            v-model="date"
            placeholder="开始日期"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "pvClickShort",
  data() {
    return {
      updateShow: false,
      updateId: "",
      shortUrlAdd: "",
      shortUrlAdd: "",
      sourceAdd: "",
      title: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "日期",
          dataIndex: "date",
        },
        {
          title: "来源",
          dataIndex: "source",
        },
        {
          title: "短链",
          dataIndex: "shortUrl",
        },
        {
          title: "pv",
          dataIndex: "pv",
        },
        {
          title: "uv",
          dataIndex: "uv",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      shortPagination: {},
      currentPage: 1,
      syncLoading: null,
      shortUrl: "",
      source: "",
      loading: false,
      loadingTable: false,
      intelligenceTime: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("day").format("YYYY-MM-DD"),
      ],
      disTime: "",
      dateFormatList: ["DD/MM/YYYY", "DD/MM/YY"],
      date: null,
    };
  },
  computed: {
    dateTime() {
      // 开始时间
      if (this.date) {
        return this.$common.transformTime1(this.date);
      }
      return null;
    },
  },
  mounted() {
    var date = new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    this.disTime = year + seperator1 + month + seperator1 + strDate;
    let startTime = new Date();
    this.date = moment(startTime, "YYYY-MM-DD HH:mm:ss");
    this.inquire(1);
  },
  methods: {
    moment, // 引用moment方法

    // 重新统计
    modifyClick(val) {
      //   this.updateId = val.id;
      let data = {
        id: val.id,
      };
      this.$http
        .get("/upv/reSummary", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("重新统计成功");
            this.inquire();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //  确定修改
    update_submit() {
      let data = {
        shortUrl: this.shortUrlAdd,
        source: this.sourceAdd,
        date: this.dateTime,
      };
      this.loading = true;
      //   if (this.title === "新增") {
      this.$http
        .json_post("/upv/addWithDate", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.updateShow = false;
            this.inquire();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
      //   } else if (this.title === "编辑") {
      //     data.id = this.updateId;
      //     this.$http
      //       .json_post("/longUrlValue/edit", data)
      //       .then((res) => {
      //         this.loading = false;
      //         if (res.result === 200) {
      //           this.$message.success("修改成功");
      //           this.updateShow = false;
      //           this.inquire();
      //         }
      //       })
      //       .catch((err) => {
      //         this.loading = false;
      //       });
      //   }
    },
    //  关闭修改
    updateClose() {
      this.shortUrlAdd = "";
      this.sourceAdd = "";
    },
    // 点击查询
    inquire(index) {
      let data = {
        pageNo: index || this.currentPage,
        pageSize: 10,
        shortUrl: this.shortUrl,
        source: this.source,
        queryShortUrlData: true,
      };
      if (this.intelligenceTime[0]) {
        data.dateStart = this.intelligenceTime[0];
        data.dateEnd = this.intelligenceTime[1];
      }
      this.getSmsStatusInfo(data);
    },
    // 查询点击数据列表
    getSmsStatusInfo(data) {
      this.loadingTable = true;
      this.$http
        .get("/upv/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.shortPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.shortChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 管理分页
    shortChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 点击新增
    addClick() {
      this.title = "新增";
      this.updateShow = true;
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除【" + val.shortUrl + "】",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/upv/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
    // 不可选日期
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 选择时间
    onChangeIntelligenceTime(dates, dateStrings) {
      this.intelligenceTime = [dateStrings[0], dateStrings[1]];
    },
  },
};
</script>
