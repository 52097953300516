<template>
  <div class="shortUrlPhone">
    <div class="searchCondition">
      <div class="searchConditionItem">
        手机号：
        <a-input
          v-model.trim="phone"
          placeholder="请输入手机号"
          @pressEnter="query()"
          @change="allowClearChange"
          allowClear
          style="width: 300px"
        />
      </div>
      <div class="searchButton">
        <a-button
          type="primary"
          :disabled="!phone"
          @click="query()"
          :loading="loadingTable"
          >查 询</a-button
        >
      </div>
    </div>
    <!-- 短链列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      size="middle"
      :loading="loadingTable"
    >
    </a-table>
  </div>
</template>

<script>
export default {
  name: "shortUrlCode",
  data() {
    return {
      phone: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "短链分组Id",
          dataIndex: "groupId",
        },
        {
          title: "域名",
          dataIndex: "domain",
        },
        {
          title: "活动编码",
          dataIndex: "activityCodeLabel",
          scopedSlots: { customRender: "activityCodeLabel" },
        },
        {
          title: "用户标识",
          dataIndex: "userCode",
        },
        {
          title: "长链",
          dataIndex: "url",
        },
        {
          title: "过期时间",
          dataIndex: "expireDate",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      queryTimer: null,
    };
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query() {
      let data = {
        phone: this.phone,
      };
      this.loadingTable = true;
      this.$http
        .get("/shortUrl/findByPhone", data)
        .then((res) => {
          if (res.result === 200) {
            this.findByPhoneResult(res.data);
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    findByPhoneResult(uuid) {
      this.$http
        .get("/shortUrl/findByPhoneResult", { uuid })
        .then((res) => {
          if (res.result === 200) {
            if (res.data) {
              this.loadingTable = false;
              let list = res.data;
              list.forEach((item, index) => {
                if (item.activityCode === item.activitySubCode) {
                  item.activityCodeLabel = item.activityCode;
                } else {
                  item.activityCodeLabel =
                    item.activityCode + " ( " + item.activitySubCode + " )";
                }
              });
              this.tableDataSource = list;
            } else {
              this.queryTimer = setTimeout(() => {
                this.findByPhoneResult(uuid);
              }, 1000);
            }
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
  beforeDestroy() {
    if (this.queryTimer) {
      clearTimeout(this.queryTimer);
      this.queryTimer = null;
    }
  },
};
</script>
