<template>
  <div class="smsStatusInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        批次编号：
        <a-input v-model="activityCodes" placeholder="请输入批次编号" />
      </div>
      <div class="searchConditionItem">
        批次子编号：
        <a-input v-model="activitySubCode" placeholder="请输入批次编号" />
      </div>
      <div class="searchConditionItem">
        业务类型：
        <a-select v-model="category" placeholder="请选择类型">
          <a-select-option value="游戏"> 游戏 </a-select-option>
          <a-select-option value="教育"> 教育 </a-select-option>
          <a-select-option value="贷款"> 贷款 </a-select-option>
          <a-select-option value="保险"> 保险 </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        创建时间：
        <a-date-picker
          :disabledDate="disabledGmtCreatedStartDate"
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          v-model="startTime"
          placeholder="开始日期"
          @openChange="handleGmtCreatedStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          :disabledDate="disabledGmtCreatedEndDate"
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="结束日期"
          v-model="endTime"
          :open="gmtEndOpen"
          @openChange="handleGmtCreatedEndOpenChange"
        />
      </div>
      <div class="searchConditionItem">
        shortCode：
        <a-input v-model="shortCode" placeholder="请输入shortCode" />
      </div>
      <div class="searchConditionItem">
        长链：
        <a-input v-model="longUrl" placeholder="请输入长链" />
      </div>
      <div class="searchConditionItem">
        设备信息：
        <a-select
          v-model="deviceInfosList"
          @change="deviceInfosChange"
          mode="tags"
          style="width: 240px"
          placeholder="请选择设备信息"
        >
          <a-select-option v-for="i in deviceInfos_list" :key="i.value">
            {{ i.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <a-checkbox
          :checked="filterWindows"
          @change="filterWindowsChange"
          style="line-height: 32px"
        >
          过滤Windows
        </a-checkbox>
      </div>
      <div class="searchConditionItem">
        <a-checkbox
          :checked="filterLegal"
          @change="filterLegalChange"
          style="line-height: 32px"
        >
          过滤非法点击
        </a-checkbox>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button @click="downloadClick">customerCode下载</a-button>
        <a-button @click="downloadHome">归属地下载</a-button>
      </div>
    </div>
    <!-- 短链列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="shortPagination"
      size="middle"
      :loading="loading"
    >
      <span slot="activityCodeLabel" slot-scope="text">
        <span>{{ text }}</span>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
      </span>
    </a-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "clickDataSource",
  data() {
    return {
      category: undefined,
      deviceInfos_list: [
        {
          value: "Windows",
          label: "Windows",
        },
        {
          value: "Android",
          label: "Android",
        },
        {
          value: "iOS",
          label: "iOS",
        },
      ],
      deviceInfosList: [],
      deviceInfos: "",
      filterWindows: false,
      filterLegal: true,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "批次编号",
          dataIndex: "activityCodeLabel",
          scopedSlots: { customRender: "activityCodeLabel" },
        },
        {
          title: "手机号",
          dataIndex: "userCode",
        },
        {
          title: "设备信息",
          dataIndex: "deviceInfo",
        },
        {
          title: "设备详情",
          dataIndex: "brand",
        },
        {
          title: "访问ip",
          dataIndex: "ip",
        },
        {
          title: "长链",
          dataIndex: "longUrl",
        },
        {
          title: "shortCode",
          dataIndex: "shortCode",
        },
        {
          title: "业务类型",
          dataIndex: "category",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
      ],
      tableDataSource: [],
      shortPagination: {},
      currentPage: 1,
      syncLoading: null,
      activityCodes: "",
      shortCode: "",
      longUrl: "",
      activitySubCode: "",
      gmtEndOpen: false,
      startTime: null,
      endTime: null,
      loading: false,
    };
  },
  mounted() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    this.startTime = moment(start, "YYYY-MM-DD HH:mm:ss");
    this.inquire(1);
  },
  methods: {
    // 点击修改
    modifyClick(val) {},
    // 点击过滤filterWindows
    filterWindowsChange(e) {
      this.filterWindows = e.target.checked;
    },
    // 点击过滤是否合法
    filterLegalChange(e) {
      this.filterLegal = e.target.checked;
    },
    // 选择设备信息
    deviceInfosChange(val) {
      this.deviceInfos = val.join(",");
    },
    // 点击查询
    inquire(index) {
      let startTime = "";
      let endTime = "";
      if (this.startTime) {
        startTime = this.$common.transformTime(this.startTime);
      }
      if (this.endTime) {
        endTime = this.$common.transformTime(this.endTime);
      }
      let data = {
        pageNo: index || this.currentPage,
        pageSize: 10,
        activityCodes: this.activityCodes,
        shortCode: this.shortCode,
        longUrl: this.longUrl,
        activitySubCode: this.activitySubCode,
        filterWindows: this.filterWindows,
        legal: this.filterLegal,
        deviceInfos: this.deviceInfos,
        gmtCreatedStart: startTime,
        gmtCreatedEnd: endTime,
        category: this.category,
      };
      this.getSmsStatusInfo(data);
    },
    // 查询点击数据列表
    getSmsStatusInfo(data) {
      this.loading = true;
      this.$http
        .get("/activityItem/list", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              item.sendTime = this.$common.transformTime(item.sendTime);
              if (item.activityCode === item.activitySubCode) {
                item.activityCodeLabel = item.activityCode;
              } else {
                item.activityCodeLabel =
                  item.activityCode + " ( " + item.activitySubCode + " )";
              }
            });
            this.tableDataSource = list;
            this.shortPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.shortChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 管理分页
    shortChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 点击手机号下载
    downloadClick() {
      let startTime = "";
      let endTime = "";
      if (this.startTime) {
        startTime = this.$common.transformTime(this.startTime);
      }
      if (this.endTime) {
        endTime = this.$common.transformTime(this.endTime);
      }
      let data = {
        activityCodes: this.activityCodes,
        shortCode: this.shortCode,
        longUrl: encodeURIComponent(this.longUrl),
        activitySubCode: this.activitySubCode,
        filterWindows: this.filterWindows,
        legal: this.filterLegal,
        deviceInfos: this.deviceInfos,
        gmtCreatedStart: startTime,
        gmtCreatedEnd: endTime,
        category: this.category,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/activityItem/download?" +
        url;
      window.location.href = href;
    },
    // 点击归属地下载
    downloadHome() {
      let startTime = "";
      let endTime = "";
      if (this.startTime) {
        startTime = this.$common.transformTime(this.startTime);
      }
      if (this.endTime) {
        endTime = this.$common.transformTime(this.endTime);
      }
      let data = {
        activityCodes: this.activityCodes,
        shortCode: this.shortCode,
        longUrl: this.longUrl,
        activitySubCode: this.activitySubCode,
        filterWindows: this.filterWindows,
        legal: this.filterLegal,
        deviceInfos: this.deviceInfos,
        gmtCreatedStart: startTime,
        gmtCreatedEnd: endTime,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/activityItem/phoneDetailDownload?" +
        url;
      this.syncLoading = this.$syncLoading({
        message: "下载中...",
      });
      window.location.href = href;
    },
    // 获取百分比（此处保留了一位小数）
    progressBar(progressEvent) {
      let percentage = Number(
        ((progressEvent.loaded / progressEvent.total) * 100).toFixed(1)
      );
      if (percentage === 100) {
        this.syncLoading.close();
      }
    },
    // 不可选的发送日期
    disabledGmtCreatedStartDate(startValue) {
      const endValue = this.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledGmtCreatedEndDate(endValue) {
      const startValue = this.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 选择发送日期
    handleGmtCreatedStartOpenChange(open) {
      if (!open) {
        this.gmtEndOpen = true;
      }
    },
    handleGmtCreatedEndOpenChange(open) {
      this.gmtEndOpen = open;
    },
  },
};
</script>
