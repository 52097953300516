<template>
  <div class="longUrlGroup">
    <div class="searchCondition">
      <div class="searchConditionItem">
        分组名称：
        <a-input
          placeholder="请输入分组名称"
          v-model="name"
          @keyup.enter="query(1)"
          allow-clear
        />
      </div>
      <div class="searchConditionItem">
        路由类型：
        <a-select
          v-model="routingType"
          placeholder="请选择路由类型"
          allow-clear
          @change="query(1)"
        >
          <a-select-option value="WITH_ORDER"> 轮循 </a-select-option>
          <a-select-option value="RANDOM"> 随机 </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        是否启用：
        <a-checkbox v-model="enable" @change="query(1)" />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)">查询</a-button>
        <a-button type="primary" @click="add">新增</a-button>
        <a-button type="primary" @click="batchAdd">批量上传</a-button>
      </div>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
    >
      <span slot="enable" slot-scope="text, record">
        <a-switch
          checked-children="启用"
          un-checked-children="禁用"
          v-model="record.enable"
          @change="enableChange($event, record.id)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyExpireAt(record)">设置失效时间</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 7 }">
        <a-form-item label="分组名称:" required>
          <a-input v-model="nameAdd" placeholder="请输入分组名称" />
        </a-form-item>
        <a-form-item label="路由类型:" required>
          <a-radio-group v-model="routingTypeAdd" @change="routingTypeChange">
            <a-radio value="WITH_ORDER">轮循</a-radio>
            <a-radio value="RANDOM">随机</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <div class="searchButton">
        <a-button type="primary" @click="addLongUrlGroupItem">新增</a-button>
        <a-button type="primary" @click="batchAddLongUrlGroupItem">
          批量新增
        </a-button>
        <a-button
          type="primary"
          @click="batchModifyItemExpireAt"
          :disabled="selectedRowKeys.length == 0"
        >
          批量设置失效时间
        </a-button>
      </div>
      <a-table
        :columns="longUrlGroupItemColumns"
        :dataSource="longUrlGroupItemList"
        :pagination="false"
        size="middle"
        :rowKey="(record) => record.id"
        :row-selection="{
          onChange: onSelectChange,
          selectedRowKeys: selectedRowKeys,
          getCheckboxProps: (record) => ({
            props: {
              disabled: record.disabled,
            },
          }),
        }"
      >
        <span slot="longUrlName" slot-scope="text, record">
          <a-input v-model="record.longUrlName" />
        </span>
        <span slot="longUrl" slot-scope="text, record">
          <a-input v-model="record.longUrl" />
        </span>
        <span slot="pvLimit" slot-scope="text, record">
          {{ record.pv }}/
          <a-input v-model="record.pvLimit" style="width: 110px" />
        </span>
        <span slot="weight" slot-scope="text, record">
          <a-input v-model="record.weight" />
        </span>
        <span slot="enabled" slot-scope="text, record">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="record.enabled"
          />
        </span>
        <span slot="priority" slot-scope="text, record">
          <a-input v-model="record.priority" />
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a
            href="javascript:;"
            :disabled="record.disabled"
            @click="modifyItemExpireAt(record)"
            >设置失效时间</a
          >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteLongUrlGroupItem(index)"
          >
            <template slot="title">是否确认删除{{ record.name }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <a-form style="margin-top: 10px">
        <a-form-item
          label="默认长链:"
          required
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 7 }"
        >
          <a-input v-model="defaultLongUrlAdd" placeholder="请输入默认长链" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 22 }"
        >
          <a-textarea :rows="5" v-model="remarkAdd" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="批量上传"
      v-model="batchAddShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="上传文件:" required>
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
        <a-form-item label="模板:">
          <a-button type="link" @click="downloadTemplate">下载模板</a-button>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="batchAddShow = false">取 消</a-button>
          <a-button type="primary" @click="batchAddSubmit" :loading="loading"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="批量新增"
      v-model="batchAddLongUrlGroupItemShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="长链:">
          <a-textarea
            :rows="5"
            v-model="batchLongUrl"
            placeholder="请输入长链"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="batchAddLongUrlGroupItemShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="batchAddLongUrlGroupItem_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="设置失效时间"
      v-model="modifyExpireAtShow"
      v-if="modifyExpireAtShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="失效时间:">
          <a-date-picker
            v-model="expireAt"
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="失效时间"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyExpireAtShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="modifyExpireAtSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/longUrlGroup.js";

export default {
  name: "longUrlGroup",
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      remarkAdd: "",
      routingType: undefined,
      enable: true,
      routingTypeAdd: "WITH_ORDER",
      defaultLongUrlAdd: "",
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "序号",
          dataIndex: "id",
        },
        {
          title: "分组名称",
          dataIndex: "name",
        },
        {
          title: "默认长链",
          dataIndex: "defaultLongUrl",
        },
        {
          title: "路由类型",
          dataIndex: "routingText",
        },
        {
          title: "是否启用",
          dataIndex: "enable",
          scopedSlots: { customRender: "enable" },
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "失效时间",
          dataIndex: "expireAt",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      allLongUrlGroupItemColumns: [
        {
          title: "名称",
          dataIndex: "longUrlName",
          width: 100,
          scopedSlots: { customRender: "longUrlName" },
        },
        {
          title: "长链",
          dataIndex: "longUrl",
          scopedSlots: { customRender: "longUrl" },
        },
        {
          title: "pv数/pv上限",
          dataIndex: "pvLimit",
          width: 100,
          scopedSlots: { customRender: "pvLimit" },
        },
        {
          title: "权重",
          dataIndex: "weight",
          width: 50,
          scopedSlots: { customRender: "weight" },
        },
        {
          title: "优先级",
          dataIndex: "priority",
          width: 50,
          scopedSlots: { customRender: "priority" },
        },
        {
          title: "启用",
          dataIndex: "enabled",
          width: 70,
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "失效时间",
          dataIndex: "expireAt",
          width: 165,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 60,
          scopedSlots: { customRender: "action" },
        },
      ],
      longUrlGroupItemColumns: [
        {
          title: "名称",
          dataIndex: "longUrlName",
          width: 100,
          scopedSlots: { customRender: "longUrlName" },
        },
        {
          title: "长链",
          dataIndex: "longUrl",
          scopedSlots: { customRender: "longUrl" },
        },
        {
          title: "pv数/pv上限",
          dataIndex: "pvLimit",
          width: 100,
          scopedSlots: { customRender: "pvLimit" },
        },
        {
          title: "优先级",
          dataIndex: "priority",
          width: 50,
          scopedSlots: { customRender: "priority" },
        },
        {
          title: "启用",
          dataIndex: "enabled",
          width: 70,
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "失效时间",
          dataIndex: "expireAt",
          width: 165,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 60,
          scopedSlots: { customRender: "action" },
        },
      ],
      longUrlGroupItemList: [],
      batchAddLongUrlGroupItemShow: false,
      batchLongUrl: "",
      gmtCreated: "",
      expireAt: "",
      ids: [],
      modifyExpireAtShow: false,
      selectedRowKeys: [],
      batchAddShow: false,
      fileList: [],
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
        routingType: this.routingType,
      };
      if (this.enable) {
        data.enable = this.enable;
      }
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tableDataSource.forEach((item) => {
              if (item.routingType == "RANDOM") {
                this.$set(item, "routingText", "随机");
              } else if (item.routingType == "WITH_ORDER") {
                this.$set(item, "routingText", "轮循");
              }
            });

            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    enableChange(enable, id) {
      let data = {
        enable,
        id,
      };
      api.updateEnable(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
        }
      });
    },
    modifyExpireAt(val) {
      this.groupId = val.id;
      this.expireAt = this.getExpireAt(val.gmtCreated, 5);
      this.modifyExpireAtShow = true;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys.splice(0);
      selectedRows.forEach((item) => {
        this.selectedRowKeys.push(item.id);
      });
    },
    batchModifyItemExpireAt() {
      this.ids.splice(0);
      this.ids.push(...this.selectedRowKeys);
      this.expireAt = this.getExpireAt(this.gmtCreated, 1);
      this.modifyExpireAtShow = true;
    },
    modifyItemExpireAt(val) {
      this.ids.splice(0);
      this.ids.push(val.id);
      this.expireAt = this.getExpireAt(this.gmtCreated, 1);
      this.modifyExpireAtShow = true;
    },
    getExpireAt(date, day) {
      var currentDate = new Date(date);
      currentDate.setDate(currentDate.getDate() + day);

      // 设置时间为 23:59:59
      currentDate.setHours(23);
      currentDate.setMinutes(59);
      currentDate.setSeconds(59);

      // 格式化日期
      var formattedDate =
        currentDate.getFullYear() +
        "-" +
        ("0" + (currentDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + currentDate.getDate()).slice(-2) +
        " " +
        "23:59:59";
      return formattedDate;
    },
    modifyExpireAtSubmit() {
      if (this.addKeyVisible) {
        let data = {
          ids: this.ids,
          expireAt: this.expireAt,
        };
        api.itemEditExpire(data).then((res) => {
          if (res.result === 200) {
            this.modifyExpireAtShow = false;
            this.ids.splice(0);
            this.selectedRowKeys.splice(0);
            this.$message.success("修改成功");
            this.modifyClick();
          }
        });
      } else {
        let data = {
          id: this.groupId,
          expireAt: this.expireAt,
        };
        api.editExpire(data).then((res) => {
          if (res.result === 200) {
            this.modifyExpireAtShow = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.selectedRowKeys.splice(0);
      if (val) {
        this.groupId = val.id;
        this.gmtCreated = val.gmtCreated;
      }
      api.groupDetail({ groupId: this.groupId }).then((res) => {
        if (res.result === 200) {
          this.nameAdd = res.data.name;
          this.remarkAdd = res.data.remark;
          this.routingTypeAdd = res.data.routingType;
          this.defaultLongUrlAdd = res.data.defaultLongUrl;
          this.longUrlGroupItemList.splice(0);
          this.longUrlGroupItemList.push(...res.data.longUrlGroupItemList);
          this.longUrlGroupItemList.forEach((item) => {
            this.$set(item, "disabled", false);
          });
          this.routingTypeChange();
          this.addKeyVisible = true;
        }
      });
    },
    // 新增
    add() {
      this.title = "新增";
      this.nameAdd = "";
      this.remarkAdd = "";
      this.routingTypeAdd = "WITH_ORDER";
      this.defaultLongUrlAdd = "";
      this.longUrlGroupItemList.splice(0);
      this.longUrlGroupItemList.push({
        longUrlName: "",
        longUrl: "",
        pvLimit: "",
        weight: "",
        enabled: true,
        priority: "",
        pv: 0,
        disabled: true,
        id: this.getID(),
      });
      this.addKeyVisible = true;
      this.routingTypeChange();
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
        remark: this.remarkAdd,
        routingType: this.routingTypeAdd,
        defaultLongUrl: this.defaultLongUrlAdd,
        longUrlGroupItemList: this.longUrlGroupItemList,
      };
      data.longUrlGroupItemList.forEach((item) => {
        if (item.disabled) {
          delete item.id;
        }
        delete item.disabled;
      });
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    batchAdd() {
      this.fileList.splice(0);
      this.batchAddShow = true;
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    batchAddSubmit() {
      if (this.fileList.length == 0) {
        this.$message.success({
          type: "info",
          message: "请先上传附件！",
        });
        return;
      }

      this.loading = true;

      let data = {
        file: this.fileList[0],
      };
      this.$http
        .files("/longUrlGroup/uploadBatchGroup", data)
        .then((res) => {
          if (res.result == 200) {
            this.loading = false;
            this.batchAddShow = false;
            this.query(1);
            this.fileList.splice(0);
            this.$message.success("上传成功");
          }
        })
        .catch(() => {
          this.loading = false;
          this.fileList.splice(0);
        });
    },
    downloadTemplate() {
      location.href =
        "https://file.huiyu.org.cn/api/fileSpace/downloadSpaceFile?spaceDetailId=598";
    },
    routingTypeChange() {
      if (this.routingTypeAdd == "WITH_ORDER") {
        this.longUrlGroupItemColumns = this.allLongUrlGroupItemColumns.filter(
          (item) => {
            return item.dataIndex !== "weight";
          }
        );
      } else {
        this.longUrlGroupItemColumns = this.allLongUrlGroupItemColumns.filter(
          (item) => {
            return item.dataIndex !== "priority";
          }
        );
      }
    },
    batchAddLongUrlGroupItem() {
      this.batchLongUrl = "";
      this.batchAddLongUrlGroupItemShow = true;
    },
    batchAddLongUrlGroupItem_submit() {
      let arr = this.batchLongUrl.split("\n");
      if (arr.length == 0) {
        this.$message.warning("请输入长链");
        return;
      }
      arr.forEach((item) => {
        if (item.indexOf("\t") !== -1) {
          this.longUrlGroupItemList.push({
            longUrlName: item.split("\t")[0],
            longUrl: item.split("\t")[1],
            pvLimit: "",
            weight: "",
            enabled: true,
            priority: "",
            pv: 0,
            disabled: true,
            id: this.getID(),
          });
        } else {
          this.longUrlGroupItemList.push({
            longUrlName: "",
            longUrl: item,
            pvLimit: "",
            weight: "",
            enabled: true,
            priority: "",
            pv: 0,
            disabled: true,
            id: this.getID(),
          });
        }
      });
      this.batchAddLongUrlGroupItemShow = false;
    },
    addLongUrlGroupItem() {
      this.longUrlGroupItemList.push({
        longUrlName: "",
        longUrl: "",
        pvLimit: "",
        weight: "",
        enabled: true,
        priority: "",
        pv: 0,
        disabled: true,
        id: this.getID(),
      });
    },
    getID() {
      if (this.longUrlGroupItemList.length > 0) {
        let id =
          this.longUrlGroupItemList.reduce((prev, current) => {
            return prev.id > current.id ? prev : current;
          }).id + 1;

        return id;
      } else {
        return 1;
      }
    },
    deleteLongUrlGroupItem(index) {
      this.longUrlGroupItemList.splice(index, 1);
    },
  },
};
</script>
