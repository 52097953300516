<template>
  <div class="shortUrlCode">
    <div class="searchCondition">
      <div class="searchConditionItem">
        短链：
        <a-input
          v-model="codes"
          placeholder="请输入短链"
          style="width: 500px"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query()">查 询</a-button>
        <a-button type="primary" @click="add">新 增</a-button>
      </div>
    </div>
    <!-- 短链列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
      </span>
    </a-table>
    <!-- 修改短链 -->
    <a-modal
      title="修改短链"
      v-model="updateShow"
      :maskClosable="false"
      :afterClose="updateClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="长链:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="url" placeholder="请输入长链" />
        </a-form-item>
        <a-form-item
          label="过期时间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-date-picker
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            showTime
            v-model="expireDate"
            placeholder="过期时间"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="新增"
      v-model="shortLinkShow"
      :maskClosable="false"
      :afterClose="shortLinkClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="code:"
          required
        >
          <div class="s-url-input">
            <a-input v-model="name" placeholder="请输入code" />
          </div>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="长网址链接:"
          required
        >
          <a-input
            v-model="longUrl"
            placeholder="请输入长网址链接"
            @blur="compareToLongUrl(longUrl)"
          />
          <span v-if="urlFormat" class="dropbox-ts" style="color: red"
            >长网址链接格式不正确</span
          >
          <span class="dropbox-ts">示例：http://www.xxxx.com</span>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="选择域名:"
          required
        >
          <a-select
            v-model="selectDomain"
            placeholder="请选择域名"
            show-search
            @search="getDomainList"
            :filter-option="false"
          >
            <a-select-option
              v-for="i in selectDomainList"
              :key="i.id"
              :value="i.domain"
              >{{ i.domain }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shortLinkShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="shortLink_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "shortUrlCode",
  data() {
    return {
      updateShow: false,
      shortId: "",
      url: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "短链",
          dataIndex: "shortCode",
        },
        {
          title: "md5值",
          dataIndex: "sign",
        },
        {
          title: "活动编码",
          dataIndex: "activityCodeLabel",
          scopedSlots: { customRender: "activityCodeLabel" },
        },
        {
          title: "用户标识",
          dataIndex: "userCode",
        },
        {
          title: "长链",
          dataIndex: "url",
        },
        {
          title: "表号",
          dataIndex: "tableNum",
          width: 60,
        },
        {
          title: "回调更新",
          dataIndex: "clickCallbackLabel",
        },
        {
          title: "过期时间",
          dataIndex: "expireDate",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      codes: "",
      loading: false,
      loadingTable: false,
      shortLinkShow: false,
      urlFormat: false,
      selectDomain: undefined,
      selectDomainList: [],
      longUrl: "https://www.baidu.com/",
      name: "",
      expireDate: "",
    };
  },
  computed: {
    compareToLongUrl(value) {
      if (value) {
        let httpIndex = value.indexOf("http://");
        let httpsIndex = value.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.urlFormat = false;
          return;
        }
        this.urlFormat = true;
      }
    },
  },
  mounted() {
    this.getDomainList("");
  },
  methods: {
    add() {
      this.selectDomain = this.selectDomainList[0].domain;
      this.shortLinkShow = true;
    },
    shortLink_submit() {
      let data = {
        shortCode: this.name,
        url: this.longUrl,
        domain: this.selectDomain,
      };
      this.$http.json_post("/shortUrl/add", data).then((res) => {
        if (res.result === 200) {
          this.shortLinkShow = false;
          this.$message.success("新增成功！");
          this.query();
        }
      });
    },
    shortLinkClose() {
      this.name = "";
      this.selectDomain = undefined;
      this.longUrl = "https://www.baidu.com/";
      this.urlFormat = false;
    },
    getDomainList(domain) {
      this.selectDomainList.splice(0);
      this.$http.get(`/domainInfo/domainList?domain=${domain}`).then((res) => {
        if (res.result === 200) {
          this.selectDomainList.push(...res.data);
        }
      });
    },
    // 点击修改
    modifyClick(val) {
      this.shortId = val.id;
      this.shortCodeLabel = val.shortCode;
      this.url = val.url;
      this.expireDate = val.expireDate;
      this.updateShow = true;
    },
    //  确定修改
    update_submit() {
      let data = {
        id: this.shortId,
        url: this.url,
        shortCode: this.shortCodeLabel,
        expireDate: this.expireDate,
      };
      if (data.url.indexOf("http") !== -1) {
        this.loading = true;
        this.$http
          .json_post("/shortUrl/update", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.updateShow = false;
              this.query();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        this.$message.warning("长链格式不正确");
      }
    },
    //  关闭修改
    updateClose() {
      this.url = "";
      this.shortId = "";
      this.shortCodeLabel = "";
    },
    // 查询点击数据列表
    query() {
      let data = {
        codes: this.codes,
      };
      this.loadingTable = true;
      this.$http
        .get("/shortUrl/findByCodes", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item, index) => {
              item.key = index + 1;
              item.clickCallbackLabel = "否";
              if (item.clickCallback) {
                item.clickCallbackLabel = "是";
              }
              if (item.activityCode === item.activitySubCode) {
                item.activityCodeLabel = item.activityCode;
              } else {
                item.activityCodeLabel =
                  item.activityCode + " ( " + item.activitySubCode + " )";
              }
            });
            this.tableDataSource = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
<style>
.dropbox-ts {
  font-size: 12px;
  color: #999;
  display: block;
  line-height: 16px;
}
</style>
