<template>
  <div class="pvClick">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="长链统计">
        <router-view name="three" v-if="active == '1'"></router-view>
      </a-tab-pane>
      <a-tab-pane key="2" tab="短链统计">
        <router-view name="three" v-if="active == '2'"></router-view>
      </a-tab-pane>
      <a-tab-pane key="3" tab="来源列表">
        <router-view name="three" v-if="active == '3'"></router-view>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "pvClick",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/pvClick/pvClickLong") {
          this.active = "1";
        } else if (newVal == "/pvClick/pvClickShort") {
          this.active = "2";
        } else if (newVal == "/pvClick/pvClickSource") {
          this.active = "3";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/pvClick/pvClickLong") return;
          this.$router.replace("/pvClick/pvClickLong");
        }
        if (newVal === "2") {
          if (this.$route.path == "/pvClick/pvClickShort") return;
          this.$router.replace("/pvClick/pvClickShort");
        }
        if (newVal === "3") {
          if (this.$route.path == "/pvClick/pvClickSource") return;
          this.$router.replace("/pvClick/pvClickSource");
        }
      },
    },
  },
};
</script>
