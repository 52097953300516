<template>
  <div class="smsStatusInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        活动编码：
        <a-input v-model="activityCode" placeholder="请输入活动编码" />
      </div>
      <div class="searchConditionItem">
        表号：
        <a-select
          v-model="tableNum"
          style="width: 240px"
          placeholder="请选择表名"
        >
          <a-select-option v-for="(i, index) in 100" :key="index">
            {{ index }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
      </div>
    </div>
    <!-- 短链列表 -->
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="shortPagination"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="activityCodeLabel" slot-scope="text">
        <span>{{ text }}</span>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
      </span>
    </a-table>
    <!-- 修改短链 -->
    <a-modal
      title="修改短链"
      v-model="updateShow"
      :maskClosable="false"
      :afterClose="updateClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="长链:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="url" placeholder="请输入长链" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "shortUrlList",
  data() {
    return {
      shortPagination: {},
      tableNum: 0,
      updateShow: false,
      shortId: "",
      url: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "短链",
          dataIndex: "shortCode",
        },
        {
          title: "md5值",
          dataIndex: "sign",
        },
        {
          title: "活动编码",
          dataIndex: "activityCodeLabel",
          scopedSlots: { customRender: "activityCodeLabel" },
        },
        {
          title: "用户标识",
          dataIndex: "userCode",
        },
        {
          title: "长链",
          dataIndex: "url",
        },
        // {
        //   title: "pv",
        //   dataIndex: "clickCount",
        // },
        // {
        //   title: "uv",
        //   dataIndex: "uniqueClickCount",
        // },
        {
          title: "回调更新",
          dataIndex: "clickCallbackLabel",
        },
        {
          title: "过期时间",
          dataIndex: "expireDate",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      currentPage: 1,
      syncLoading: null,
      activityCode: "",
      loading: false,
      loadingTable: false,
    };
  },
  computed: {
    prevDisabled() {
      if (this.currentPage === 1) {
        return true;
      }
      return false;
    },
    nextDisabled() {
      if (this.tableDataSource.length < 10) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击修改
    modifyClick(val) {
      this.shortId = val.id;
      this.shortCodeLabel = val.shortCode;
      this.url = val.url;
      this.updateShow = true;
    },
    //  确定修改
    update_submit() {
      let data = {
        id: this.shortId,
        url: this.url,
        shortCode: this.shortCodeLabel,
      };
      this.loading = true;
      this.$http
        .json_post("/shortUrl/update", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.updateShow = false;
            this.inquire();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //  关闭修改
    updateClose() {
      this.url = "";
      this.shortId = "";
      this.shortCodeLabel = "";
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        tableNum: this.tableNum,
        activityCode: this.activityCode,
      };
      this.getSmsStatusInfo(data);
    },
    // 查询点击数据列表
    getSmsStatusInfo(data) {
      this.loadingTable = true;
      this.$http
        .get("/shortUrl/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item, index) => {
              item.key = index + 1;
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              item.expireDate = this.$common.transformTime(item.expireDate);
              item.clickCallbackLabel = "否";
              if (item.clickCallback) {
                item.clickCallbackLabel = "是";
              }
              if (item.activityCode === item.activitySubCode) {
                item.activityCodeLabel = item.activityCode;
              } else {
                item.activityCodeLabel =
                  item.activityCode + " ( " + item.activitySubCode + " )";
              }
            });
            this.tableDataSource = list;
            this.shortPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.shortChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 管理分页
    shortChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
  },
};
</script>
