import http from "./axios";

// 查询列表
const queryPage = (data) => {
  return http.get("/clickCallback/userRelationPage", data);
};

const getClickCallbackUrlList = (data) => {
  return http.get("/clickCallback/list", data);
};

// 添加
const addData = (data) => {
  return http.json_post("/clickCallback/addUserRelation", data);
};

// 删除
const deleteData = (data) => {
  return http.json_post("/clickCallback/deleteUserRelation", data);
};

export default {
  queryPage,
  getClickCallbackUrlList,
  addData,
  deleteData,
};
