<template>
  <div class="smsStatusInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        md5：
        <a-input v-model="md5Search" placeholder="请输入md5" allowClear />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查 询</a-button>
        <a-button type="primary" @click="addClick">新 增</a-button>
      </div>
    </div>
    <!-- 参数配置列表 -->
    <a-table
      :columns="paramsColumns"
      :dataSource="paramsTableDate"
      :pagination="paramsPagination"
      size="middle"
      :loading="loadingTable"
    >
      <span slot="enabled" slot-scope="text">
        <a-tag v-if="text" color="green">启用</a-tag>
        <a-tag v-else color="red">禁用</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a
        ><a-divider type="vertical" />
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 修改参数配置 -->
    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      :afterClose="updateClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="长链:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input
            v-model="url"
            placeholder="请输入长链"
            allowClear
            @blur="inputChange($event)"
          />
        </a-form-item>
        <a-form-item
          label="md5:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="md5" placeholder="请输入md5" allowClear />
        </a-form-item>
        <a-form-item
          label="追加参数:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-select
            v-model="appendType1"
            placeholder="请选择追加参数"
            mode="multiple"
          >
            <a-select-option
              v-for="(item, index) in appendTypeList"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="追加自定义内容:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input
            v-model="appendDiyContent"
            placeholder="请输入追加自定义内容"
            allowClear
          />
        </a-form-item>
        <a-form-item
          label="是否启用:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import md5 from "js-md5";

export default {
  name: "parameter",
  data() {
    return {
      md5Search: "",
      updateShow: false,
      updateId: "",
      url: "",
      md5: "",
      appendType: "",
      appendType1: [],
      // appendCount: "",
      appendDiyContent: "",
      enabled: true,
      title: "",
      paramsColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "长链",
          dataIndex: "url",
        },
        {
          title: "md5",
          dataIndex: "md5",
        },
        {
          title: "追加参数",
          dataIndex: "appendType",
        },
        // {
        //   title: "追加次数",
        //   dataIndex: "appendCount",
        // },
        {
          title: "追加自定义内容",
          dataIndex: "appendDiyContent",
        },
        {
          title: "状态",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 40,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      paramsTableDate: [],
      paramsPagination: {},
      currentPage: 1,
      syncLoading: null,
      activityCode: "",
      loading: false,
      loadingTable: false,
      appendTypeList: [],
      md5Txt: "",
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击修改
    modifyClick(val) {
      this.getAppendType();
      this.updateId = val.id;
      this.url = val.url;
      this.md5 = val.md5;
      // this.appendType = val.appendType;
      this.appendType1 = val.appendType.split(",");
      // this.appendCount = val.appendCount;
      this.appendDiyContent = val.appendDiyContent;
      this.enabled = val.enabled;
      this.updateShow = true;
      this.title = "编辑";
    },
    //  确定修改
    update_submit() {
      let data = {
        url: this.url,
        md5: this.md5,
        appendType: this.appendType1.join(","),
        appendDiyContent: this.appendDiyContent,
        enabled: this.enabled,
      };
      this.loading = true;
      if (this.title === "新增") {
        this.$http
          .json_post("/parameterAppend/add", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.updateShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else if (this.title === "编辑") {
        data.id = this.updateId;
        this.$http
          .json_post("/parameterAppend/update", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.updateShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    //  关闭修改
    updateClose() {
      this.url = "";
      this.md5 = "";
      this.appendType1 = [];
      // this.appendCount = "";
      this.appendDiyContent = "";
      this.enabled = true;
    },
    // 点击查询
    inquire(index) {
      let data = {
        pageNo: index || this.currentPage,
        pageSize: 10,
        md5: this.md5Search,
      };
      this.getSmsStatusInfo(data);
    },
    // 查询参数配置列表
    getSmsStatusInfo(data) {
      this.loadingTable = true;
      this.$http
        .get("/parameterAppend/page", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.paramsTableDate = list;
            this.paramsPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.paramsChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    paramsChangePage(index) {
      this.currentPage = index;
      this.inquire(index);
    },
    // 点击新增
    addClick() {
      this.title = "新增";
      this.updateShow = true;
      this.getAppendType();
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除该条数据吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http.get("/parameterAppend/delete", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire(1);
            }
          });
        },
        onCancel() {},
      });
    },
    // 获取追加参数
    // /parameterAppend/appendType
    getAppendType() {
      this.$http.get("/parameterAppend/appendType").then((res) => {
        if (res.result === 200) {
          this.appendTypeList = res.data.typeList;
        }
      });
    },
    // 失去光标事件
    inputChange(event) {
      this.md5Txt = event.target.value.split("/")[2];
      this.md5 = md5(this.md5Txt);
    },
  },
};
</script>
